<template>
  <el-container class="is-vertical">
    <layout-header></layout-header>
    <layout-main></layout-main>
  </el-container>
</template>

<script>
import LayoutHeader from '@/components/layout/header'
import LayoutMain from '@/components/layout/main'
export default {
  name: 'Layout',
  components: {
    LayoutHeader,
    LayoutMain
  },
  data () {
    return {}
  },
  methods: {
    toView (name) {
      this.$router.push({ name: name })
    }
  }
}
</script>
<style lang="less">
</style>
