<template>
  <div class="l-mask">
    <div class="l-mask-bg" @click="close()"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LMask',
  props: {},
  created() {},
  data() {
    return {}
  },
  methods: {
    close() {
      document.body.removeChild(this.$el)
    }
  }
}
</script>

<style lang="less" scoped>
.l-mask {
  z-index: 2000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.l-mask-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
</style>
