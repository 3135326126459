<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="80%">
    <el-tabs type="border-card" value="base" @tab-click="tabClick">
      <el-tab-pane name="base" label="订单详细" :style="panelStyle">
        <evorder-base :ev-order="dataInfo"></evorder-base>
      </el-tab-pane>
      <el-tab-pane name="gps" label="订单轨迹" :style="panelStyle">
        <evorder-gps v-if="gpsVisible" :ev-order="dataInfo"></evorder-gps>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import evorderBase from './base'
import evorderGps from './gps'
export default {
  components: { evorderBase, evorderGps },
  data() {
    return {
      dataInfo: null,
      gpsVisible: false,
      panelStyle: { height: document.documentElement.clientHeight * 0.9 - 180 + 'px', overflow: 'auto' }
    }
  },
  mounted() {},
  methods: {
    tabClick(e) {
      if (e.name === 'gps') this.gpsVisible = true
    }
  }
}
</script>

<style scoped>
.el-tabs__content {
  padding: 0;
}
</style>
