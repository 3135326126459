import { Message, MessageBox, Notification } from 'element-ui'
const obj = {
  alert(text, callback, option = {}) {
    MessageBox(
      Object.assign(
        {
          title: '提示',
          message: text,
          showClose: false,
          closeOnClickModal: false,
          callback: (action, instance) => {
            callback && callback(action, instance)
          }
        },
        option
      )
    )
  },
  msgSuccess(text, callback, option = {}) {
    this.alert(text, callback, Object.assign({ type: 'success' }), option)
  },
  msgWarning(text, callback, option = {}) {
    this.alert(text, callback, Object.assign({ type: 'warning' }), option)
  },
  msgInfo(text, callback, option = {}) {
    this.alert(text, callback, Object.assign({ type: 'info' }), option)
  },
  msgError(text, callback, option = {}) {
    this.alert(text, callback, Object.assign({ type: 'error' }), option)
  },
  msgConfirm(text, confirm, option = {}) {
    this.alert(
      text,
      (action, instance) => {
        if (action === 'confirm') {
          confirm && confirm()
        } else {
          option.cancel && option.cancel()
        }
      },
      Object.assign({
        type: 'warning',
        showClose: false,
        showCancelButton: true,
        closeOnClickModal: false
      }),
      option
    )
  },
  msgPrompt(title, confirm, option = {}) {
    this.alert(
      '',
      (action, instance) => {
        if (action === 'confirm') {
          confirm && confirm(instance.inputValue)
        } else {
          option && option.cancel && option.cancel()
        }
      },
      Object.assign({
        title: title,
        type: 'info',
        showClose: false,
        showCancelButton: true,
        closeOnClickModal: false,
        showInput: true
      },
      option)
    )
  },
  toast(text, callback, option = {}) {
    Message(
      Object.assign(
        {
          message: text,
          onClose: () => {
            callback && callback()
          }
        },
        option
      )
    )
  },
  toastSuccess(text, callback, option = {}) {
    this.toast(text, callback, Object.assign({ type: 'success' }, option))
  },
  toastWarning(text, callback, option = {}) {
    this.toast(text, callback, Object.assign({ type: 'warning' }, option))
  },
  toastInfo(text, callback, option = {}) {
    this.toast(text, callback, Object.assign({ type: 'info' }, option))
  },
  toastError(text, callback, option = {}) {
    this.toast(text, callback, Object.assign({ type: 'error' }, option))
  },
  notify(title, text, callback, option = {}) {
    Notification(
      Object.assign(
        {
          title: title,
          message: text,
          onClick: () => {
            callback && callback()
          }
        },
        option
      )
    )
  },
  notifySuccess(title, text, callback, option = {}) {
    this.notify(title, text, callback, Object.assign({ type: 'success' }, option))
  },
  notifyWarning(title, text, callback, option = {}) {
    this.notify(title, text, callback, Object.assign({ type: 'warning' }, option))
  },
  notifyInfo(title, text, callback, option = {}) {
    this.notify(title, text, callback, Object.assign({ type: 'info' }, option))
  },
  notifyError(title, text, callback, option = {}) {
    this.notify(title, text, callback, Object.assign({ type: 'error' }, option))
  }
}
export default obj
