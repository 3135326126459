<template>
  <el-main :style="{ height: $store.state.mainHeight + 'px', overflow: 'auto' }">
    <transition>
      <router-view />
    </transition>
  </el-main>
</template>

<script>
export default {
  name: 'LayoutContent',
  props: {},
  mounted() {
    this.setMainHeight()
    window.onresize = this.setMainHeight
  },
  data() {
    return {}
  },
  methods: {
    setMainHeight() {
      this.$store.commit('SET_MAIN_HEIGHT', document.documentElement.clientHeight - 60)
    }
  }
}
</script>
<style lang="less"></style>
