import ajax from '@/lgh/utils/ajax'

const api = {
  enterpriseList: {
    prop: ['enterpriseCode', 'enterpriseName'],
    promise(condition) {
      return ajax.get('api/comm/enterprise/list', condition, false)
    }
  },
  departList: {
    prop: ['departId', 'departName'],
    promise(condition) {
      return ajax.get('api/enterprise/depart/list', ['enterpriseCode', condition], false)
    }
  },
  adviceType: {
    prop: ['smallCode', 'smallName'],
    promise(condition) {
      return ajax.get('api/comm/advicetype/list', ['enterpriseCode', condition], false)
    }
  },
  adviceCategory: {
    prop: ['smallCode', 'smallName'],
    promise(condition) {
      return ajax.get('api/comm/advicecategory/list', ['enterpriseCode', condition], false)
    }
  },
  adviceEvaluateLevel: {
    prop: ['levelName', 'levelName'],
    promise(condition) {
      return ajax.get('api/enterprise/advice/evaluatelevel', ['enterpriseCode', condition], false)
    }
  },
  safelyMatterType: {
    prop: ['smallCode', 'smallName'],
    promise(condition) {
      return ajax.get('api/comm/safely/mattertype/list', ['enterpriseCode', condition], false)
    }
  },
  proposalType: {
    prop: ['smallCode', 'smallName'],
    promise(condition) {
      return ajax.get('api/comm/proposaltype/list', ['enterpriseCode', condition], false)
    }
  },
  assetCategoryList: {
    prop: ['categoryCode', 'categoryName'],
    promise(condition) {
      return ajax.get('api/enterprise/asset/category', ['enterpriseCode', condition], false)
    }
  },
  roomList: {
    prop: ['roomId', 'roomName'],
    promise(condition) {
      return ajax.get('api/enterprise/mettingroom/list', ['enterpriseCode', condition], false)
    }
  },
  roleList: {
    prop: ['roleId', 'roleName'],
    promise(condition) {
      return ajax.get('api/enterprise/role/list', ['enterpriseCode', condition], false)
    }
  },
  searchUser: {
    prop: ['userId', 'fullName'],
    isRemote: true,
    fromPage: true,
    promise(query, condition) {
      if (query === '') return new Promise(resolve => resolve([]))
      Object.assign(condition, { keyword: query })
      return ajax.get('api/enterprise/employee/list', ['enterpriseCode', condition], false)
    }
  }
}
export default api
