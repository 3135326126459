<template>
  <el-table
    v-if="tbOption"
    :data="data"
    :height="tbOption.height"
    :max-height="tbOption.maxHeight"
    :stripe="tbOption.stripe"
    :border="tbOption.border === false ? false : true"
    :size="tbOption.size"
    :fit="tbOption.fit"
    :show-header="tbOption.showHeader"
    :highlight-current-row="tbOption.highlightCurrentRow"
    :current-row-key="tbOption.currentRowKey"
    :row-key="tbOption.rowKey"
    :empty-text="tbOption.emptyText"
    :default-sort="tbOption.defaultSort"
    :tooltip-effect="tbOption.tooltipEffect"
    @selection-change="handleSelectionChange"
    @row-click="handleRowClick"
    @sort-change="handleSortChange"
    style="width:100%"
  >
    <!--列：checkbox选择-->
    <el-table-column v-if="tbOption.hasSelect" type="selection" width="55"></el-table-column>

    <!--列：expand展开-->
    <el-table-column v-if="tbOption.hasExpand" type="expand">
      <template v-if="tbOption.expandColumns" slot-scope="scope">
        <l-preview :data="scope.row" :span="tbOption.expandSpan" :columns="tbOption.expandColumns"> </l-preview>
      </template>
      <template v-else slot-scope="scope">
        <slot name="$expand" :row="scope.row"></slot>
      </template>
    </el-table-column>

    <template v-for="(item, index) in tbOption.columns">
      <!--列：序号列-->
      <el-table-column
        v-if="item.type == 'index'"
        :key="index"
        type="index"
        :fixed="item.fixed"
        :align="item.align || 'center'"
        :label="item.label"
        :class-name="item.className || ''"
        :width="item.width || '60px'"
        :index="startIndex"
        :min-width="item.minWidth || ''"
      >
      </el-table-column>
      <!--列：使用插槽的列-->
      <el-table-column
        v-else-if="item.slot"
        :key="index"
        :sortable='item.sortable'
        :fixed="item.fixed"
        :show-overflow-tooltip="item.overflow ? true : false"
        :align="item.align ? item.align : 'center'"
        :label="item.label"
        :class-name="item.className || ''"
        :width="item.width || ''"
        :min-width="item.minWidth || ''"
      >
        <template slot-scope="scope">
          <slot :name="item.slot" :row="scope.row" :index="scope.$index"></slot>
        </template>
      </el-table-column>
      <!--列：普通显示(带filter)-->
      <el-table-column
        v-else-if="item.filter"
        :key="index"
        :fixed="item.fixed"
        :show-overflow-tooltip="item.overflow ? true : false"
        :align="item.align || 'center'"
        :label="item.label"
        :class-name="item.className || ''"
        :width="item.width || ''"
        :min-width="item.minWidth || ''"
      >
        <template slot-scope="scope">
          <span>{{ item.filter(scope.row[item.prop], scope.row, scope.$index) }}</span>
        </template>
      </el-table-column>
      <!--列：普通显示-->
      <el-table-column
        v-else
        :key="index"
        :sortable='item.sortable'
        :fixed="item.fixed"
        :show-overflow-tooltip="item.overflow ? true : false"
        :align="item.align || 'center'"
        :label="item.label"
        :prop="item.prop"
        :class-name="item.className || ''"
        :width="item.width || ''"
        :min-width="item.minWidth || ''"
      >
      </el-table-column>
    </template>
    <!--列：显示按钮组-->
    <el-table-column
      column-key="operation"
      :fixed="tbOption.operation.fixed"
      :align="tbOption.operation.align || 'center'"
      :label="tbOption.operation.label"
      :width="tbOption.operation.width || ''"
      :min-width="tbOption.operation.minWidth || ''"
      :class-name="tbOption.operation.className"
      v-if="tbOption.operation"
    >
      <template slot="header">
        <template v-if="tbOption.operation.header">
          <el-button
            :class="tbOption.operation.header.classname || ''"
            :type="tbOption.operation.header.type"
            :icon="tbOption.operation.header.icon"
            :size="tbOption.operation.header.size || 'mini'"
            :plain="tbOption.operation.header.plain"
            :round="tbOption.operation.header.round"
            :circle="tbOption.operation.header.circle"
            @click.stop="tbOption.operation.header.click && tbOption.operation.header.click()"
            >{{ tbOption.operation.header.label }}
          </el-button>
        </template>
        <span v-else>{{ tbOption.operation.label || '操作' }}</span>
      </template>

      <template slot-scope="scope">
        <slot :name="tbOption.operation.slot" :row="scope.row" :index="scope.$index" v-if="tbOption.operation.slot"></slot>
        <el-button
          v-else
          v-for="(item, index) in tbOption.operation.buttons"
          :class="item.classname || ''"
          :key="index"
          :type="item.type"
          :icon="item.icon"
          :size="item.size || 'mini'"
          :plain="item.plain"
          :round="item.round"
          :circle="item.circle"
          @click.stop="item.click && item.click(scope.row, scope.$index)"
          ><span v-if="item.label">{{ item.label }}</span></el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- @click.stop="handleOperation(item.action, scope, row, scope.$index)" -->
</template>

<script>
import LPreview from '../../preview/src/main'
export default {
  name: 'LTable',
  components: { LPreview },
  props: {
    option: {
      type: Object,
      default: null
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    startIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tbOption: null
    }
  },
  mounted() {
    if (this.option.stripe && !this.option.height) {
      this.option.height = this.$lgh.stripeTableHeight(this.option.hOption)
    }
    this.tbOption = this.option
  },
  methods: {
    handleSelectionChange() {},
    handleRowClick() {},
    handleSortChange() {},
    handleOperation () { }
  },
  watch: {}
}
</script>
