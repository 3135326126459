<template>
  <el-container>
    <layout-aside class="layout-silde"></layout-aside>
    <layout-content></layout-content>
  </el-container>
</template>
<script>
import LayoutAside from '@/components/layout/aside'
import LayoutContent from '@/components/layout/content'
export default {
  name: 'LayoutMain',
  components: {
    LayoutAside,
    LayoutContent
  },
  data () {
    return {}
  },
  methods: {
  }
}
</script>
<style lang="less">
</style>
