<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="90%">
    <el-tabs type="border-card" value="base" @tab-click="tabChange">
      <el-tab-pane name="base" label="基本信息" :style="panelStyle">
        <el-card class="margin-xs">
          <l-description :option="descOption" :data="descData">
            <template slot="projectType">
              <p class="text-blue" v-if="dataInfo.projectType == 'duty'">管理类</p>
              <p class="text-purple" v-if="dataInfo.projectType == 'asset'">资产类</p>
              <p class="text-purple" v-if="dataInfo.projectType == 'item'">临时类</p>
            </template>
            <template slot="projectState">
              <span v-if="dataInfo.projectState == 9" class="text-red">已关闭</span>
              <span v-else-if="dataInfo.projectState == 0" class="text-blue">未启动</span>
              <span v-else-if="dataInfo.projectState == 1" class="text-green">运行中</span>
            </template>
          </l-description>
        </el-card>
      </el-tab-pane>
      <el-tab-pane name="task" label="任务记录" :style="panelStyle">
        <el-card class="margin-xs" v-if="taskList">
          <l-table :option="taskTableOption" :data="taskList.list" :start-index="taskList.startIndex">
            <template slot="taskName" slot-scope="scope">
              <el-button type="text" @click="$site.view_safely_regular_task_detail(scope.row.taskId)">{{ scope.row.taskName }}</el-button>
            </template>
            <template slot="matterStatusName" slot-scope="scope">
              <span v-if="scope.row.matterStatus == 0" class="text-grey">未提交</span>
              <span v-else-if="scope.row.matterStatus == 1" class="text-blue">已提交</span>
              <span v-else-if="scope.row.matterStatus == 10" class="text-green">已入围</span>
              <span v-else-if="scope.row.matterStatus == 9" class="text-red">无效建议</span>
            </template>
            <template slot="effectStatusName" slot-scope="scope">
              <span v-if="scope.row.effectStatus == 0" class="text-grey">---</span>
              <span v-else-if="scope.row.effectStatus == 1" class="text-blue">实施中</span>
              <span v-else-if="scope.row.effectStatus == 2" class="text-green">已完成</span>
              <span v-else-if="scope.row.effectStatus == 8" class="text-red">无法实施</span>
              <span v-else-if="scope.row.effectStatus == 9" class="text-red">暂缓实施</span>
            </template>
          </l-table>
          <l-pager :page="taskList" @change="getTaskList"></l-pager>
        </el-card>
      </el-tab-pane>
      <el-tab-pane name="plan" label="计划任务" :style="panelStyle">
        <el-card class="margin-xs" v-if="planList">
          <l-table :option="planTableOption" :data="planList.list">
            <template slot="itemState" slot-scope="scope">
              <span v-if="scope.row.dealState == 0" class="text-gray">未检测</span>
              <span v-else-if="scope.row.itemState == 1" class="text-green">完好</span>
              <span v-else-if="scope.row.itemState == 2" class="text-red">缺损</span>
            </template>
            <template slot="fee" slot-scope="scope">
              <span v-if="scope.row.dealState == 0" class="text-gray">---</span>
              <span v-else-if="scope.row.itemState == 1" class="text-green">---</span>
              <span v-else-if="scope.row.itemState == 2" class="text-red">{{ scope.row.fee }}</span>
            </template>
          </l-table>
          <l-pager :page="planList" @change="getPlanList"></l-pager>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      descOption: {
        column: 4,
        size: 'medium ',
        labelStyle: {
          width: '100px'
        }
      },
      descData: null,
      imgList: null,
      preImgList: [],
      panelStyle: { height: document.documentElement.clientHeight * 0.9 - 200 + 'px', overflow: 'auto' },
      taskTableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '任务编号', prop: 'taskNo', overflow: true },
          { label: '任务名称', slot: 'taskName', overflow: true },
          { label: '负责部门', prop: 'departName' },
          { label: '负责人', prop: 'userName' },
          { label: '实施部门', prop: 'effectDepartName' },
          { label: '实施人', prop: 'effectUserName' },
          { label: '完成人', prop: 'dealUserName' },
          { label: '任务开始时间', prop: 'startTime' },
          { label: '任务结束时间', prop: 'endTime' },
          { label: '实际完成时间', prop: 'dealTime' }
        ]
      },
      planTableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '任务名称', prop: 'taskName' },
          { label: '责任人', prop: 'userName' },
          { label: '实施人', prop: 'effectUserName' },
          { label: '任务创建时间', prop: 'createTime' },
          { label: '任务开始时间', prop: 'startTime' },
          { label: '任务结束时间', prop: 'endTime' }
        ]
      },
      planList: null,
      taskList: null
    }
  },
  mounted() {
    this.descData = [
      { label: '项目名称', text: this.dataInfo.projectName, span: 2 },
      { label: '项目分类', slot: 'projectType' },
      { label: '项目状态', slot: 'projectState' },
      { label: '周期', text: this.dataInfo.triggerDesc, span: 4 },
      { label: '责任部门', text: this.dataInfo.departName },
      { label: '责任人', text: this.dataInfo.userName },
      { label: '实施部门', text: this.dataInfo.effectDepartName },
      { label: '实施人', text: this.dataInfo.effectUserName },
      { label: '潜在风险', text: this.dataInfo.riskDesc, span: 4 },
      { label: '风险状态', text: this.dataInfo.riskState },
      { label: '风险时态', text: this.dataInfo.riskTimeState },
      { label: '危险因素分类', text: this.dataInfo.riskFactorCodeName },
      { label: '危险程度', text: this.dataInfo.riskLevelName },
      { label: 'L值', text: this.dataInfo.lec },
      { label: 'E值', text: this.dataInfo.lec },
      { label: 'C值', text: this.dataInfo.lec },
      { label: 'LEC值', text: this.dataInfo.lec },
      { label: '管控要求', text: this.dataInfo.requirement, span: 4 },
      { label: '管控标准', text: this.dataInfo.standardNo, span: 4 },
      { label: '备注信息', text: this.dataInfo.remark, span: 4 }
    ]
  },
  methods: {
    tabChange(e) {
      if (e.name === 'plan') this.getPlanList()
      if (e.name === 'task') this.getTaskList()
    },
    async getPlanList(pageOption) {
      this.planList = await this.$lgh.get('api/enterprise/safely/regular/project/plan/list', Object.assign({ projectId: this.dataInfo.projectId }, pageOption))
      this.planList.list.forEach(a => {
        a.userName = this.dataInfo.userName
        a.effectUserName = this.dataInfo.effectUserName
        a.trustUserName = this.dataInfo.trustUserName
      })
    },
    async getTaskList(pageOption) {
      this.taskList = await this.$lgh.get('api/enterprise/safely/regular/task/pagedlist', Object.assign({ projectId: this.dataInfo.projectId }, pageOption))
    }
  }
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
