<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh">
    <el-row>
      <el-col class="padding-mini">
        <el-card class="l-condition">
          <el-form :inline="true" :model="condition">
            <el-form-item label="所属部门">
              <l-select v-model="condition.departId" :remote="$api.departList" @change="getPageData()"></l-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input v-model="condition.keyword" size="small" placeholder="姓名|电话|身份证|地址"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-divider></el-divider>
        <el-card class="overflow" v-if="pageData">
          <l-table :option="tableOption" :data="pageData.list">
            <template slot="name" slot-scope="scope">
              <div class="flex text-center">
                <span class="padding-left-xs" :class="scope.row.employeeType == 10 ? 'text-purple' : ''">{{ scope.row.name }}</span>
              </div>
            </template>
            <template slot="mType" slot-scope="scope">
              <span v-if="scope.row.mType == 'formal'">正式员工</span>
              <span v-else-if="scope.row.mType == 'external'" class="text-cyan">外雇员工</span>
            </template>
            <template slot="title" slot-scope="scope">
              <el-tag type="success" effect="dark" size="mini" v-if="scope.row.isBoss" class="margin-right-mini">公司老板</el-tag>
              <el-tag type="success" effect="dark" size="mini" v-if="scope.row.isSenior" class="margin-right-mini">企业高管</el-tag>
              <el-tag type="success" effect="dark" size="mini" v-if="scope.row.isLeader" class="margin-right-mini">部门领导</el-tag>
              <el-tag effect="dark" size="mini" v-if="!scope.row.isBoss && !scope.row.isSenior && !scope.row.isLeader" class="margin-right-mini">普通员工</el-tag>
              <el-tag type="warning" effect="dark" size="mini" v-if="scope.row.isAdmin" class="margin-right-mini">管理员</el-tag>
              <el-tag type="primary" effect="dark" size="mini" v-if="scope.row.title">{{ scope.row.title }}</el-tag>
            </template>
            <template slot="operation" slot-scope="scope">
              <el-button v-if="scope.row.disabled == false" type="primary" icon="el-icon-position" size="mini" @click="sel(scope.row)">选择</el-button>
            </template>
          </l-table>
          <l-pager :page="pageData" @change="getPageData"></l-pager>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      selUserIds: [],
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight * 0.9 - 300 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '姓名', slot: 'name' },
          { label: '手机号', prop: 'mobile' },
          { label: '性别', prop: 'gender' },
          { label: '部门', prop: 'departName' },
          { label: '员工类型', slot: 'mType' },
          { label: '员工标签', slot: 'title' },
          { label: '操作', slot: 'operation' }
        ]
      },
      condition: {
        keyword: '',
        enterpriseCode: '',
        departId: '',
        mType: '',
        isQuit: false,
        isLeader: '',
        isSenior: '',
        sort: 'selCount',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null,
      extra: null
    }
  },
  created() {
    if (!this.extra) this.extra = {}
    if (this.extra.userId) {
      this.selUserIds = [this.extra.userId]
    }
    if (this.extra.userIds) {
      this.selUserIds = this.extra.userIds
    }
    this.condition.enterpriseCode = this.enterpriseCode
    this.getPageData()
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/employee/list', this.condition)
      this.pageData.list.forEach(a => (a.disabled = this.selUserIds.indexOf(a.userId) !== -1))
    },
    sel(item) {
      this.visible = false
      this.onsuccess && this.onsuccess(item)
    }
  }
}
</script>

<style scoped></style>
