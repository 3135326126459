<template>
  <div class="l-amap-container">
    <div class="l-amap" :id="mapId"></div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
var map = null
export default {
  name: 'LAmap',
  props: {
    plugins: {
      type: Array,
      default: () => []
    },
    mapStyle: {
      type: String,
      default: 'amap://styles/grey'
    },
    zoom: {
      type: Number,
      default: 11
    },
    center: {
      type: Array,
      default: () => [118.787975, 31.983388]
    }
  },
  data() {
    return {
      mapId: 'map' + parseInt(Math.random() * 1000)
    }
  },
  created() {
    map = null
  },
  mounted() {
    AMapLoader.load({
      key: process.env.VUE_APP_AMAP_KEY, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: this.plugins // 插件列表
    })
      .then(AMap => {
        this.initMap()
      })
      .catch(e => {
        console.log(e)
      })
  },
  destroyed(a) {
    if (map) {
      map.destroy()
      map = null
    }
  },
  methods: {
    getMap() {
      return map
    },
    initMap() {
      map = new AMap.Map(this.mapId, {
        mapStyle: this.mapStyle,
        resizeEnable: true,
        rotateEnable: false,
        pitchEnable: true,
        zoom: this.zoom,
        viewMode: '3D', // 开启3D视图,默认为关闭
        center: this.center
      })
      map.on('complete', () => {
        this.$emit('complete', map)
      })
    }
  },
  watch: {}
}
</script>
<style lang="less" scoped>
.l-amap-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.l-amap {
  height: 100%;
  width: 100%;
  resize: both;
  cursor: default;
  background-color: rgb(244, 244, 244);
}

.amap-logo {
  opacity: 0;
}
.amap-copyright {
  opacity: 0;
}
</style>
