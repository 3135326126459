import Vue from 'vue'

const obj = {
  openDialog(option, parent) {
    return new Promise(resolve => {
      const DialogConstructor = Vue.extend(option.ins)
      var constructorParam = {
        el: document.createElement('div'),
        data() {
          return Object.assign(
            {
              title: option.title,
              visible: true
            },
            option.data
          )
        },
        methods: {
          _closed() {
            document.body.removeChild(instance.$el)
            this.$destroy()
            option.onclosed && option.onclosed()
          },
          _close() {
            this.visible = false
          },
          _cancle() {
            this.visible = false
            this.cancle && instance.cancle()
          },
          onsuccess(res) {
            this._close()
            resolve(res)
          }
        }
      }
      if (typeof parent !== 'undefined') {
        Object.assign(constructorParam, {
          store: parent.$store, // 向新的vue实例传递全局变量vuexStore
          router: parent.$router, // 向新的vue实例传递全局变量vueRouter
          route: parent.$route // 向新的vue实例传递全局变量vueRouter
        })
      }
      var instance = new DialogConstructor(constructorParam)

      // instance.vm = instance.$mount()
      document.body.appendChild(instance.$el)
      Vue.nextTick(() => {
        instance.visible = true
      })
    })
  }
}
export default obj
