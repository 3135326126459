<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="90%">
    <div v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carNo" slot-scope="scope">
          <el-button type="text" @click="$site.view_car_detail(scope.row.carId)">{{ scope.row.carNo }}</el-button>
        </template>
      </l-table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dataInfo: null,
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight * 0.9 - 64 - 80 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', slot: 'carNo' },
          { label: 'VIN码', prop: 'vin', overflow: true },
          { label: '型号', prop: 'modelName', overflow: true },
          { label: '车辆性质', prop: 'carNatureText' },
          { label: '车辆状态', prop: 'carStatusText' },
          { label: '位置状态', prop: 'fenceDesc', overflow: true },
          { label: '交强险到期', prop: 'insuranceJQXExpireDate', filter: a => this.$lgh.limitTo(a, 10) },
          { label: '商业险险到期', prop: 'insuranceSYXExpireDate', filter: a => this.$lgh.limitTo(a, 10) },
          { label: '年检到期', prop: 'yearlyInspectExpireDate', filter: a => this.$lgh.limitTo(a, 10) }
          // { label: '责任人', prop: 'reponsibleUserName' }
        ]
      },
      condition: {
        keyword: '',
        carNature: '',
        carStatus: '',
        pageIndex: 1,
        pageSize: 10,
        _isAll: true
      },
      pageData: null
    }
  },
  mounted() {
    Object.assign(this.condition, this.dataInfo)
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/ecar/list', ['enterpriseCode', this.condition])
    }
  }
}
</script>

<style scoped></style>
