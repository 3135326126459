<template>
  <el-descriptions
    v-if="option"
    :column="option.column"
    :size="option.size"
    :border="option.border == false ? false : true"
    :labelClassName="option.labelClassName"
    :contentClassName="option.contentClassName"
    :labelStyle="option.labelStyle"
    :contentStyle="option.contentStyle"
  >
    <el-descriptions-item v-for="(item, index) in data" :key="index" :span="item.span">
      <template slot="label">
        <i v-if="item.iconClass" :class="item.iconClass"></i>
        {{ item.label }}
      </template>
      <div v-if="item.slot">
        <slot :name="item.slot" :prop="item.prop"></slot>
      </div>
      <div v-else>{{ item.text }}</div>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
export default {
  name: 'LDescription',
  props: {
    option: {
      type: Object,
      default: null
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    startIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleSelectionChange() {},
    handleRowClick() {},
    handleSortChange() {},
    handleOperation() {}
  },
  watch: {}
}
</script>
