const filter = {
  date: (value, defaultText, length) => {
    if (!defaultText) defaultText = ''
    if (!length) length = 10
    return !value ? defaultText : value.substring(0, length)
  },
  limitTo: (value, length) => {
    if (!value) return value
    return value.substring(0, length)
  }
}

export default filter
