<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-row v-if="dataInfo && employee" id="borrowPrint" class="padding">
      <div class="text-center text-bold text-xs padding padding-top-0">私车公用审批表</div>
      <table class="table-print">
        <tr>
          <th class="text-bold" style="width:15%">车牌号</th>
          <td style="width:35%">{{ dataInfo.carNo }}</td>
          <th class="text-bold" style="width:15%">姓名</th>
          <td>{{ dataInfo.userName }}</td>
        </tr>
        <tr>
          <th class="text-bold">所属部门</th>
          <td>{{ employee.departName }}</td>
          <th class="text-bold">岗位名称</th>
          <td>{{ employee.title }}</td>
        </tr>
        <tr>
          <th class="text-bold">出发时间</th>
          <td>{{ dataInfo.startTime | limitTo(16) }}</td>
          <th class="text-bold">到达时间</th>
          <td>{{ dataInfo.endTime | limitTo(16) }}</td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="padding-sm text-left">
              <p>出车事由：[{{ dataInfo.workType }}]</p>
              <p class="padding-xs">{{ dataInfo.desc || '无' }}</p>
              <p class="fr padding-xs">
                <span>申请人：</span>
                <span style="padding-left:15px;">{{ dataInfo.userName }}</span>
                <span style="padding-left:50px">申请日期：</span>
                <span style="padding-left:15px;">{{ dataInfo.createTime | limitTo(10) }}</span>
              </p>
            </div>
          </td>
        </tr>
        <tr>
          <th class="text-bold">出车线路</th>
          <td colspan="3" style="height:150px;">
            <div class="text-left padding-left">
              <span v-for="(item, index) in dataInfo.posList" :key="index">{{ item.place }}<span v-if="index != dataInfo.posList.length - 1"> → </span></span>
            </div>
          </td>
        </tr>
        <!-- <tr>
          <th class="text-bold">费用明细</th>
          <td colspan="3" style="margin:0;padding:0;border:0;">
            <table class="table-print" style="margin:0;padding:0;border:0;">
              <tr v-for="(item, index) in dataInfo.feeList" :key="index" style="height:40px;">
                <td style="width:58.8234%">{{ item.feeName }}</td>
                <td>{{ item.fee }} 元</td>
              </tr>
            </table>
          </td>
        </tr> -->
        <tr>
          <th class="text-bold" :rowspan="feeCount == 0 ? 1 : feeCount">费用明细</th>
          <td colspan="2" style="height:40px;">{{ firstFee.feeName }}</td>
          <td>{{ firstFee.fee }} 元</td>
        </tr>
        <tr v-for="(item, index) in dataInfo.feeList" :key="index" v-show="index > 0">
          <td colspan="2" style="height:40px;">{{ item.feeName }}</td>
          <td>{{ item.fee }} 元</td>
        </tr>
        <tr>
          <th class="text-bold">公里数</th>
          <td>
            <span v-if="dataInfo.startMileage && dataInfo.endMileage">{{ dataInfo.endMileage - dataInfo.startMileage }} 公里</span>
          </td>
          <th class="text-bold">合计费用</th>
          <td>{{ dataInfo.feeTotal }} 元</td>
        </tr>
        <tr>
          <th class="text-bold">审核意见</th>
          <td colspan="3">
            <div class="padding-sm">
              <p class="padding margin"></p>
              <p class="fr padding-xs">
                <span>审核人:</span>
                <span style="padding-left:15px;">{{ dataInfo.verifyUserName }}</span>
                <span style="padding-left:50px">审核日期:</span>
                <span style="padding-left:15px;">{{ dataInfo.verifyTime | limitTo(10) }}</span>
              </p>
            </div>
          </td>
        </tr>
      </table>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button v-print="'#borrowPrint'" type="primary">点击打印</el-button>
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      employee: null,
      dataColumns: [
        { label: '车牌号', prop: 'carNo' },
        { label: '审核状态', slot: 'borrowState' },
        { label: '用车人', prop: 'userName' },
        { label: '审核人', prop: 'verifyUserName' },
        { label: '用车事由', prop: 'workType' },
        { label: '审核时间', prop: 'verifyTime' },
        { label: '预计出发时间', prop: 'startTime' },
        { label: '预计到达时间', prop: 'endTime' },
        { label: '相关说明', prop: 'desc' }
      ],
      feeCount: 0,
      firstFee: {}
    }
  },
  mounted() {
    this.$lgh.get('api/enterprise/employee/info', { userId: this.dataInfo.userId }, false).then(res => {
      this.employee = res
    })

    if (this.dataInfo && this.dataInfo.feeList && this.dataInfo.feeList.length !== 0) {
      this.feeCount = this.dataInfo.feeList.length
      this.firstFee = this.dataInfo.feeList[0]
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    print() {
      window.print()
    }
  }
}
</script>

<style scoped></style>
