<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row>
        <el-col class="padding-right-xs">
          <el-row class="twice">
            <el-form-item label="建议标题" prop="proposalTitle">
              <el-input v-model="dataInfo.proposalTitle" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="建议状态" prop="proposalStatus">
              <p class="padding-left text-bold">
                <span v-if="dataInfo.proposalStatus == 0" class="text-grey">未提交</span>
                <span v-else-if="dataInfo.proposalStatus == 1" class="text-blue">已提交</span>
                <span v-else-if="dataInfo.proposalStatus == 2" class="text-mauve">已入围</span>
                <span v-else-if="dataInfo.proposalStatus == 3" class="text-green">已采纳</span>
                <span v-else-if="dataInfo.proposalStatus == -1" class="text-red">无效建议</span>
                <span v-else-if="dataInfo.proposalStatus == -2" class="text-red">无法实施</span>
              </p>
            </el-form-item>
            <el-form-item label="建议类型" prop="proposalType">
              <el-input v-model="dataInfo.proposalType" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="建议分类" prop="proposalCategory">
              <el-input v-model="dataInfo.proposalCategory" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="建议人" prop="createUserName">
              <el-input v-model="dataInfo.createUserName" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="所属部门" prop="createDepartName">
              <el-input v-model="dataInfo.createDepartName" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="提出时间" prop="proposalTime">
              <l-datepicker v-model="dataInfo.proposalTime" :readonly="true"></l-datepicker>
            </el-form-item>
            <el-form-item label="要求时间" prop="requestTime">
              <l-datepicker v-model="dataInfo.requestTime" :readonly="true"></l-datepicker>
            </el-form-item>
            <el-form-item label="建议内容" prop="content">
              <el-input type="textarea" :rows="3" v-model="dataInfo.content" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="改进方法" prop="proposalMethod">
              <el-input type="textarea" :rows="3" v-model="dataInfo.proposalMethod" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="改进结果" prop="proposalResult">
              <el-input type="textarea" :rows="3" v-model="dataInfo.proposalResult" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="入围说明" prop="approveInfo" v-if="dataInfo.proposalStatus === -2 || dataInfo.proposalStatus >= 2">
              <el-input type="textarea" :rows="3" v-model="dataInfo.approveInfo" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="无效说明" prop="approveInfo" v-if="dataInfo.proposalStatus === -1">
              <el-input type="textarea" :rows="3" v-model="dataInfo.approveInfo" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="采纳说明" prop="departmentSuggest" v-if="dataInfo.proposalStatus === 3">
              <el-input type="textarea" :rows="3" v-model="dataInfo.departmentSuggest" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="无法实施说明" prop="departmentSuggest" v-if="dataInfo.proposalStatus === -2">
              <el-input type="textarea" :rows="3" v-model="dataInfo.departmentSuggest" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      editType: '',
      activeName: 'base',
      dataRules: {}
    }
  },
  methods: {}
}
</script>

<style></style>
