<template>
  <el-select
    v-model="selectValue"
    :placeholder="placeholder"
    :multiple="multiple"
    :filterable="isFilterable"
    :remote="isRemote"
    :remote-method="remoteMethod"
    :loading="loading"
    :size="size"
    :collapse-tags="collapseTags"
    @change="onChange"
  >
    <el-option v-if="hasAll" :label="allLabel" :value="''"> </el-option>
    <el-option v-for="(item, index) in dataList" :key="index" :label="item.text" :value="item.code"> </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'LSelect',
  props: {
    modelVal: {},
    multiple: {},
    collapseTags: {},
    placeholder: {
      type: String,
      default: '请选择'
    },
    size: {
      type: String,
      default: 'small'
    },
    loading: {
      type: Boolean,
      default: false
    },
    all: {
      type: Boolean,
      default: true
    },
    allLabel: {
      type: String,
      default: '--不限--'
    },
    data: {
      type: [Array, Object, String],
      default: null
    },
    prop: {
      type: Array,
      default: null
    },
    remote: {
      type: [String, Object, Function],
      default: null
    },
    condition: {
      type: Object,
      default: null
    }
  },
  model: {
    prop: 'modelVal',
    event: 'input'
  },
  data() {
    return {
      hasAll: false,
      selectValue: null,
      dataList: [],
      dataProp: null,
      isFilterable: false,
      isRemote: false,
      remoteMethod() {}
    }
  },
  mounted() {
    this.hasAll = this.all
    this.dataProp = this.prop
    this.selectValue = this.modelVal || ''
    this.initData()
  },
  methods: {
    initData() {
      if (this.remote == null) {
        this.setData(this.data)
      } else if (typeof this.remote === 'string') {
        this.getDataAsync(this.remote)
      } else if (typeof this.remote === 'object') {
        this.getDataFromAPI(this.remote)
      } else if (typeof this.remote === 'function') {
        this.isFilterable = true
        this.isRemote = true
        // this.hasAll = false
        this.remoteMethod = query => {
          this.remote(query, this.condition)
        }
      }
    },
    setData(data) {
      if (!data) {
        this.dataList = []
      } else if (data instanceof Array) {
        this.dataList = data.map(p => {
          if (typeof p === 'string') {
            return { code: p, text: p }
          } else if (this.dataProp == null) {
            return p
          } else {
            return { code: p[this.dataProp[0]], text: p[this.dataProp[1]] }
          }
        })
      } else if (typeof data === 'object') {
        var r = []
        for (var key in data) {
          r.push({ code: key, text: data[key] })
        }
        this.dataList = r
      }
    },
    getDataAsync(url) {
      this.$lgh.get(url, this.condition, false).then(res => {
        this.setData(res)
      })
    },
    getDataFromAPI(apiData) {
      if (apiData.prop) this.dataProp = apiData.prop
      if (apiData.isRemote) {
        this.isFilterable = true
        this.isRemote = true
        // this.hasAll = false
        this.remoteMethod = query => {
          apiData.promise(query, this.condition).then(res => {
            this.setData(apiData.fromPage ? res.list : res)
          })
        }
      } else {
        apiData.promise(this.condition).then(res => {
          this.setData(res)
        })
      }
    },
    onChange(e) {
      this.$emit('input', e)
      this.$emit('change', e)
    }
  },
  watch: {
    modelVal(value) {
      this.selectValue = this.modelVal || ''
    },
    data(value) {
      this.setData(value)
    },
    condition(value) {
      if (typeof this.remote === 'string') {
        this.getDataAsync(this.remote)
      } else if (typeof this.remote === 'object') {
        this.getDataFromAPI(this.remote)
      }
    }
  }
}
</script>
