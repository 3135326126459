<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-row v-if="dataInfo">
      <el-col class="padding-xs padding-bottom-0">
        <el-card>
          <l-preview :data="dataInfo" :span="12" :columns="dataColumns"> </l-preview>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataColumns: [
        { label: '资产名称', prop: 'assetName' },
        { label: '资产编号', prop: 'assetCode' },
        { label: '资产类别', prop: 'assetTypeName' },
        { label: '资产分类', prop: 'categoryName' },
        { label: '登记人', prop: 'createUserName' },
        { label: '登记时间', prop: 'createTime' },
        { label: '责任人', prop: 'reponsibleUserName' },
        { label: '使用人', prop: 'useUserName' },
        { label: '采购时间', prop: 'purchaseTime' },
        { label: '采购人', prop: 'purchaseUserName' },
        { label: '采购人部门', prop: 'purchaseDepartName' },
        { label: '过期时间', prop: 'expiredDate' },
        { label: '设备规格型号', prop: 'deviceModel' },
        { label: '设备厂商', prop: 'deviceVendor' },
        { label: '购建原值', prop: 'price' },
        { label: '预计残值', prop: 'remainPrice' },
        { label: '折旧月份', prop: 'depreciationMonth' },
        { label: '净值', prop: 'worthPrice' },
        { label: '备注', prop: 'remarks' }
      ]
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style scoped></style>
