<template>
  <div class="l-pager" v-if="page">
    <el-pagination
      :background="background"
      :current-page="page.pageIndex"
      :page-size="page.pageSize"
      :layout="layout"
      align="center"
      :page-sizes="pageSizes"
      :total="page.totalCount"
      :prev-text="prevText"
      :next-text="nextText"
      hide-on-single-page
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'LPager',
  props: {
    pageSizes: {
      type: Array,
      default() {
        return [10, 15, 20, 30, 50, 100]
      }
    },
    background: {
      type: Boolean,
      default: true
    },
    page: {
      type: Object,
      default: null
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prevText: '',
      nextText: '',
      clsName: '',
      layout: 'total, sizes, prev, pager, next, jumper'
    }
  },
  mounted() {
    if (this.simple) {
      this.prevText = ' 上一页 '
      this.nextText = ' 下一页 '
      this.layout = 'total, sizes, prev, next'
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('change', { pageIndex: 1, pageSize: val })
    },
    handleCurrentChange(val) {
      this.$emit('change', {
        pageIndex: val,
        pageSize: this.page.pageSize
      })
    }
  }
}
</script>

<style lang="less" scoped>
.l-pager {
  background: #fff;
  padding: 10px;
  padding-bottom: 0;
}
</style>
