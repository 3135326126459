var data0 = []
for (var i = 1; i <= 3; i++) {
  data0.push({ code: i, text: '第' + i + '月份' })
}

var data1 = []

for (i = 1; i <= 12; i++) {
  data1.push({ code: i, text: i + '月' })
}
var data2 = []

for (i = 1; i <= 31; i++) {
  data2.push({ code: i, text: i + '日' })
}

var data3 = []
for (i = 0; i < 24; i++) {
  data3.push({ code: i, text: i + '时' })
}

var data4 = []
var arr4 = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
for (i = 1; i <= 7; i++) {
  data4.push({ code: i, text: arr4[i - 1] })
}
const result = {
  BaseQuarter: data0,
  BaseMonths: data1,
  BaseDays: data2,
  BaseHours: data3,
  BaseWeeks: data4
}
export default result
