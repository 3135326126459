<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-row v-if="dataInfo">
      <el-col class="padding-xs padding-bottom-0">
        <el-card>
          <div slot="header" class="clearfix">
            <p>登记信息</p>
          </div>
          <l-preview :data="dataInfo" :span="12" :columns="dataColumns"> </l-preview>
        </el-card>
      </el-col>
      <el-col class="padding-xs">
        <el-card>
          <div slot="header" class="clearfix">
            <p>流水信息</p>
          </div>
          <p v-if="dataInfo.recordList.length == 0" class="padding text-center text-gray">
            暂无记录~
          </p>
          <el-row>
            <el-col :span="12" v-for="(item, index) in dataInfo.recordList" :key="index" class="padding-xs">
              <el-card>
                <div style="height:200px;">
                  <div v-if="item.recordType == 'text'" style="height:100%;overflow:auto;">
                    <span>{{ item.recordContent }}</span>
                  </div>
                  <div v-if="item.recordType == 'img'" style="height:100%;overflow:auto;">
                    <el-image style="max-height:100%;max-width:100%;" :src="item.recordContent" :preview-src-list="[item.recordContent]"> </el-image>
                  </div>
                  <div v-if="item.recordType == 'audio'" style="height:100%;overflow:auto;display:flex;align-items:center;justify-content:center;">
                    <audio :src="item.recordContent" controls style="width:80%;display:block;">
                      您的浏览器不支持 音频。
                    </audio>
                  </div>
                  <div v-if="item.recordType == 'video'" style="height:100%;overflow:hidden;">
                    <video :src="item.recordContent" controls muted style="height:100%;width:100%;">
                      您的浏览器不支持 视频。
                    </video>
                  </div>
                </div>
                <el-divider></el-divider>
                <div class="flex justify-between align-center padding-xs">
                  <el-tag type="primary">{{ index + 1 }}</el-tag>
                  <p>
                    <i class="el-icon-time"></i>
                    {{ item.recordTime }}
                  </p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataColumns: [
        { label: '外勤事由', prop: 'workType' },
        { label: '外勤人', prop: 'userName' },
        { label: '所属部门', prop: 'departName' },
        { label: '登记时间', prop: 'createTime' },
        { label: '计划出发时间', prop: 'planStartTime' },
        { label: '计划返回时间', prop: 'planEndTime' },
        { label: '出发地', prop: 'startPlace' },
        { label: '返回地', prop: 'endPlace' },
        { label: '相关说明', prop: 'desc' }
      ]
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style scoped></style>
