<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh">
    <el-card class="margin-xs">
      <l-description :option="descOption" :data="descData">
        <template slot="img">
          <el-col :span="6" v-for="item in dataInfo.dealImgList" :key="item">
            <el-image class="margin-xs" :src="item" :preview-src-list="dataInfo.dealImgList"> </el-image>
          </el-col>
        </template>
      </l-description>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      descOption: {
        labelStyle: {
          width: '100px'
        }
      },
      descData: null,
      preList: []
    }
  },
  mounted() {
    this.descData = [
      { label: '项目名称', text: this.dataInfo.projectName },
      { label: '任务名称', text: this.dataInfo.taskName },
      { label: '生成时间', text: this.dataInfo.createTime },
      { label: '责任人', text: this.dataInfo.departName + '_' + this.dataInfo.userName },
      { label: '实施人', text: this.dataInfo.effectDepartName + '_' + this.dataInfo.effectUserName },
      { label: '实际完成人', text: this.dataInfo.dealUserName },
      { label: '开始时间', text: this.dataInfo.startTime },
      { label: '结束时间', text: this.dataInfo.endTime },
      { label: '实际完成时间', text: this.dataInfo.dealTime },
      { label: '相关描述', text: this.dataInfo.dealRemark, span: 3 },
      { label: '相关图片', slot: 'img', span: 3 }
    ]
  },
  methods: {
    getRecordList() {
      this.$lgh.get('api/enterprise/advice/recordlist', { adviceId: this.dataInfo.adviceId }).then(res => {
        this.recordList = res
      })
    }
  }
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
