import Vue from 'vue'
import Vuex from 'vuex'
import utility from '@/lgh/utils/utility.js'
import ajax from '@/lgh/utils/ajax.js'
import { constantRoutes } from '@/router'
// import Layout from '@/views/layout'

Vue.use(Vuex)

const store = {
  state: {
    mainHeight: 300,
    FHeight: document.documentElement.clientHeight,
    token: utility.getToken(),
    userInfo: null,
    setting: null,
    routes: [],
    addRoutes: [],
    menuList: [],
    dictData: null,
    currentMenu: null,
    toggleMenu: false
  },
  mutations: {
    SET_TOKEN(state, provider) {
      state.token = provider
      if (provider == null) {
        utility.removeStore('accesstoken')
      } else {
        utility.setStore('accesstoken', provider)
      }
    },
    SET_USER(state, provider) {
      state.userInfo = provider
    },
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_SETTING: (state, provider) => {
      state.setting = provider
    },
    SET_MENUS: (state, provider) => {
      state.menuList = provider
    },
    SET_DICTDATA: (state, provider) => {
      state.dictData = provider
    },
    SET_CURRENT_MENUS: (state, provider) => {
      state.currentMenu = provider
    },
    SET_MAIN_HEIGHT: (state, provider) => {
      state.mainHeight = provider
    },
    SET_TOGGLE_MENU: (state, provider) => {
      state.toggleMenu = provider
    }
  },
  actions: {
    initData({ commit, state }) {
      return new Promise(resolve => {
        if (state.dictData) {
          resolve()
        } else {
          ajax.get('api/comm/sys/dict').then(res => {
            commit('SET_DICTDATA', res)
            resolve()
          })
        }
      })
    },
    Login({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    Logout({ commit, state }) {
      return new Promise(resolve => {
        ajax.get('api/auth/user/logout', { token: state.token }).then(res => {
          commit('SET_TOKEN', null)
          commit('SET_USER', null)
          resolve()
        })
      })
    },
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', null)
        resolve()
      })
    },
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        ajax
          .get('api/auth/user/info', { token: state.token }, e => {
            reject(e)
          })
          .then(res => {
            if (res.userinfo !== null) {
              commit('SET_SETTING', res.setting)
              commit('SET_USER', res.userinfo)
            }
            resolve(res)
          })
      })
    },
    // 生成路由
    GenerateRoutes({ commit }, menulist) {
      return new Promise(resolve => {
        commit('SET_MENUS', menulist)
        const accessedRoutes = menuToRoute(menulist)
        accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      })
    }
  },
  modules: {}
}

// 遍历后台传来的路由字符串，转换为组件对象
function menuToRoute(menulist) {
  var routeList = []
  menulist.forEach(menu => {
    var route = {
      path: menu.path,
      fullPath: menu.fullPath,
      redirect: menu.redirect,
      meta: menu.meta,
      props: menu.props,
      name: menu.code,
      hidden: true
    }
    if (menu.component === 'layout') {
      route.component = () => import('@/views/layout')
    }
    if (menu.component === 'empty') {
      route.component = () => import('@/views/layout_empty')
    } else {
      route.component = () => import('@/views/' + menu.component)
    }
    if (menu.children && menu.children.length !== 0) {
      route.children = menuToRoute(menu.children)
    }
    routeList.push(route)
  })
  return routeList
}

export default new Vuex.Store(store)
