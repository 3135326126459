/** 全局注册组件 */
import LDatepicker from './components/datepicker/index'
import LPager from './components/pager/index'
import LPreview from './components/preview/index'
import LSelect from './components/select/index'
import LTable from './components/table/index'
import LTable2 from './components/table2/index'
import LTableTop from './components/tableTop/index'
import LDescription from './components/description/index'
import LPanel from './components/panel/index'
import LUpload from './components/upload/index'
import LAbsolute from './components/absolute/index'
import LMask from './components/mask/index'
/** 非全局注册组件 */

/** 全局公用方法 */
import ajax from './utils/ajax'
import msg from './utils/msg'
import utility from './utils/utility'
import dialog from './utils/dialog'
import lfilter from './filter'

const components = [LDatepicker, LPager, LPreview, LSelect, LTable, LTable2, LTableTop, LDescription, LPanel, LUpload, LAbsolute, LMask]

const install = function(Vue, opts = {}) {
  /** 挂载全局组件 */
  components.forEach(component => {
    Vue.component(component.name, component)
  })

  /** 挂载全局属性 */
  Vue.prototype.$lgh = Object.assign({}, ajax, msg, utility, dialog)

  /** 挂载全局过滤器 */
  Object.keys(lfilter).forEach(item => {
    Vue.filter(item, lfilter[item])
  })
}

export default {
  version: '1.0.0',
  install
}
