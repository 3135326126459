<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-row v-if="dataInfo" id="borrowPrint" class="padding">
      <div class="text-center text-bold text-xs padding padding-top-0">建议打印</div>
      <table class="table-print">
        <tr>
          <th class="text-bold" style="width:15%">建议项目</th>
          <td colspan="3">{{ dataInfo.adviceTitle }}</td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">建议类型</th>
          <td style="width:35%">{{ dataInfo.adviceType }}</td>
          <th class="text-bold" style="width:15%">建议分类</th>
          <td>{{ dataInfo.adviceCategory }}</td>
        </tr>
        <tr>
          <th class="text-bold">建议人</th>
          <td>{{ dataInfo.createUserName}}</td>
          <th class="text-bold">提出部门</th>
          <td>{{ dataInfo.adviceCategory }}</td>
        </tr>
        <tr>
                  <th>提出时间</th>
                  <td>{{ dataInfo.submitTime }}</td>
                  <th>建议状态</th>
                  <td class="text-bold">
                    <span v-if="dataInfo.adviceStatus == 0">未提交</span>
                    <span v-else-if="dataInfo.adviceStatus == 1">已提交</span>
                    <span v-else-if="dataInfo.adviceStatus == 10">已入围</span>
                    <span v-else-if="dataInfo.adviceStatus == 9">无效建议</span>
                  </td>
                </tr>
        <tr>
          <th colspan="4" style="text-align: left;">
            ●存在问题
          </th>
        </tr>
        <tr>
          <td colspan="4" style="text-align: left;">
            <div class="padding-xs">{{ dataInfo.adviceContent }}</div>
          </td>
        </tr>
        <tr>
          <th colspan="4" style="text-align: left;">
            ●改进措施
          </th>
        </tr>
        <tr>
          <td colspan="4" style="text-align: left;">
            <div class="padding-xs">{{ dataInfo.adviceMethod }}</div>
          </td>
        </tr>
        <tr>
          <th colspan="4" style="text-align: left;">
            ●审核信息
          </th>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">审核人</th>
          <td style="width:35%">{{ dataInfo.verifyUserName }}</td>
          <th class="text-bold" style="width:15%">审核部门</th>
          <td>{{ dataInfo.verifyDepartName }}</td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">审核时间</th>
          <td style="width:35%">{{ dataInfo.submitTime }}</td>
          <th class="text-bold" style="width:15%">审核结果</th>
          <td>
            <p v-if="dataInfo.adviceStatus == 1">
                      已提交-
                      <span v-if="dataInfo.verifyHistoryIds">已转移</span>
                      <span v-else >未审核</span>
                    </p>
                    <p v-else-if="dataInfo.adviceStatus == 9" >无效建议</p>
                    <p v-else-if="dataInfo.adviceStatus == 10">
                      已入围-
                      <span v-if="dataInfo.effectStatus == 1">实施中</span>
                      <span v-else-if="dataInfo.effectStatus == 8">无法实施</span>
                      <span v-else-if="dataInfo.effectStatus == 9">暂缓实施</span>
                      <span v-else-if="dataInfo.effectStatus == 2">实施完成</span>
                    </p>
          </td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">审核说明</th>
          <td colspan="3">{{ dataInfo.unableDesc || dataInfo.transferDesc }}</td>
        </tr>
        <tr>
          <th colspan="4" style="text-align: left;">
            ●实施信息
          </th>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">要求时间</th>
          <td style="width:35%">{{ dataInfo.effectRequestDate }}</td>
          <th class="text-bold" style="width:15%">实施要求</th>
          <td>{{ dataInfo.effectRequestDesc }}</td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">实施人</th>
          <td style="width:35%">{{ dataInfo.effectUserName }}</td>
          <th class="text-bold" style="width:15%">实施部门</th>
          <td>{{ dataInfo.effectDepartName }}</td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">实施时间</th>
          <td style="width:35%">{{ dataInfo.effectTime }}</td>
          <th class="text-bold" style="width:15%">实施状态</th>
          <td>
            <span v-if="dataInfo.effectStatus == 0">未实施</span>
                    <span v-else-if="dataInfo.effectStatus == 1">实施中</span>
                    <span v-else-if="dataInfo.effectStatus == 2">实施完成</span>
          </td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">实施说明</th>
          <td colspan="3">{{ dataInfo.effectContent}}</td>
        </tr>
         <tr>
          <th class="text-bold" style="width:15%">实施图片</th>
          <td colspan="3">
            <el-col v-for="(item, index) in dataInfo.effectImgList" :key="index" :span="6">
                      <el-card class="padding0 margin-mini" style="line-height:0px;">
                        <el-image style="width: 100%; height: 120px" :src="item" :preview-src-list="dataInfo.effectImgList"> </el-image>
                      </el-card>
                    </el-col>
          </td>
        </tr>
        <tr>
          <th colspan="4" style="text-align: left;">
            ●建议评价
          </th>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">评价等级</th>
          <td style="width:35%">{{ dataInfo.evaluateLevel }}</td>
          <th class="text-bold" style="width:15%">节约成本</th>
          <td>{{ dataInfo.costSaving }}元</td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">奖励金额</th>
          <td style="width:35%">{{ dataInfo.evaluateMoney }}元</td>
          <th class="text-bold" style="width:15%">奖励发放</th>
          <td>
            <span v-if="dataInfo.evaluateMoneyState == 0">未发放</span>
                    <span v-else-if="dataInfo.evaluateMoneyState == 1">已发放</span>
          </td>
        </tr>
        <tr>
          <th class="text-bold" style="width:15%">评价说明</th>
          <td colspan="3">{{ dataInfo.evaluateDesc}}</td>
        </tr>
      </table>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button v-print="'#borrowPrint'" type="primary">打 印</el-button>
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      employee: null,
      dataColumns: [
        { label: '车牌号', prop: 'carNo' },
        { label: '审核状态', slot: 'borrowState' },
        { label: '用车人', prop: 'userName' },
        { label: '审核人', prop: 'verifyUserName' },
        { label: '用车事由', prop: 'workType' },
        { label: '审核时间', prop: 'verifyTime' },
        { label: '预计出发时间', prop: 'startTime' },
        { label: '预计到达时间', prop: 'endTime' },
        { label: '相关说明', prop: 'desc' }
      ],
      feeCount: 0,
      firstFee: {}
    }
  },
  mounted() {
    console.log(this.dataInfo)
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    print() {
      window.print()
    }
  }
}
</script>

<style scoped></style>
