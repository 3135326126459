<template>
  <div>
    <div class="l-table-top">
      <div class="left">
        <el-button>默认按钮</el-button>
        <el-button type="primary">主要按钮</el-button>
        <el-button type="success">成功按钮</el-button>
        <el-button type="info">信息按钮</el-button>
        <el-button type="warning">警告按钮</el-button>
        <el-button type="danger">危险按钮</el-button>
      </div>
      <div class="right">
        <el-popover placement="bottom" width="100" trigger="click">
          <el-table ref="columnTable" :data="option" tooltip-effect="dark" style="width: 100%" @cell-click="cellClick" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="45"> </el-table-column>
            <el-table-column label="全选" prop="label"> </el-table-column>
          </el-table>
          <el-button slot="reference" icon="el-icon-setting">显示列</el-button>
        </el-popover>
      </div>
    </div>
    <el-divider> </el-divider>
  </div>
</template>

<script>
export default {
  name: 'LTableTop',
  props: {
    option: {
      type: Array,
      default: null
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    startIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    cellClick(row, column, cell, event) {
      this.$refs.columnTable.toggleRowSelection(row)
    }
  },
  watch: {}
}
</script>
<style scoped lang="less">
.l-table-top {
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    flex: 1;
  }
}
</style>
