<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh">
    <el-row>
      <el-col class="padding-mini">
        <el-card class="l-condition">
          <el-form :inline="true" :model="condition">
            <el-form-item>
              <el-input v-model="condition.keyword" size="small" placeholder="车牌号/车架号"> </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-divider></el-divider>
        <el-card class="overflow" v-if="pageData">
          <l-table :option="tableOption" :data="pageData.list"> </l-table>
          <l-pager :page="pageData" @change="getPageData"></l-pager>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight * 0.9 - 300 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', prop: 'carNo' },
          { label: 'VIN码', prop: 'vin', overflow: true },
          { label: '型号', prop: 'modelName', overflow: true },
          { label: '车辆状态', prop: 'carStatusText' }
        ],
        operation: {
          width: '100',
          buttons: [{ type: 'primary', icon: 'el-icon-position', label: '选择', click: this.sel }]
        }
      },
      condition: {
        keyword: '',
        groupId: '',
        carSource: '',
        carStatus: '',
        useStatus: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.condition.enterpriseCode = this.enterpriseCode
    this.getPageData()
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/ecar/list', this.condition)
    },
    sel(item) {
      this.visible = false
      this.onsuccess && this.onsuccess(item)
    }
  }
}
</script>

<style scoped></style>
