<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh">
    <el-card class="margin-xs">
      <template #header>
        <div class="card-header">
          <span>基本信息</span>
        </div>
      </template>
      <table class="table-detail">
        <tr>
          <th>访客姓名</th>
          <td>{{ dataInfo.vistorName }}</td>
          <th>访客手机号</th>
          <td>{{ dataInfo.visitorMobile }}</td>
          <th>访客单位</th>
          <td>{{ dataInfo.vistorUnitName }}</td>
        </tr>
        <tr>
          <th>受访单位</th>
          <td>{{ dataInfo.enterpriseName }}</td>
          <th>受访人</th>
          <td>{{ dataInfo.userName }}|{{ dataInfo.userMobile }}</td>
          <th>受访部门</th>
          <td>{{ dataInfo.departName }}</td>
        </tr>
        <tr>
          <th>拜访日期</th>
          <td>{{ dataInfo.visitDate | limitTo(10) }} | {{ dataInfo.visitDurning }}</td>
          <th>随访人数</th>
          <td>{{ dataInfo.visitorCount }}</td>
          <th>本人体温</th>
          <td>{{ dataInfo.temperature }}℃</td>
        </tr>
      </table>
    </el-card>
    <el-card class="margin-xs">
      <template #header>
        <div class="card-header">
          <span>拜访事由</span>
        </div>
      </template>
      <div class="padding-xs">{{ dataInfo.visitReason || '无' }}</div>
    </el-card>
    <el-row
      ><el-col :span="12">
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>苏康码图片</span>
            </div>
          </template>
          <div class="padding-xs text-center" v-if="dataInfo.skmImgUrl">
            <el-image :src="dataInfo.skmImgUrl" style="height:300px;" :preview-src-list="preImgList"></el-image>
            <!-- <img :src="dataInfo.skmImgUrl" class="fileIcon" @click="$site.view_file(dataInfo.skmImgUrl)" /> -->
          </div>
          <p class="padding-xs" v-else>暂无</p>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>行程码图片</span>
            </div>
          </template>
          <div class="padding-xs text-center" v-if="dataInfo.xcmImgUrl">
            <el-image :src="dataInfo.xcmImgUrl" style="height:300px;" :preview-src-list="preImgList"></el-image>
            <!-- <img :src="dataInfo.skmImgUrl" class="fileIcon" @click="$site.view_file(dataInfo.skmImgUrl)" /> -->
          </div>
          <p class="padding-xs" v-else>暂无</p>
        </el-card>
      </el-col>
    </el-row>

    <el-card class="margin-xs">
      <template #header>
        <div class="card-header">
          <span>随身物品</span>
        </div>
      </template>
      <div class="padding-xs">{{ dataInfo.personalBelong || '无' }}</div>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      imgList: null,
      preImgList: [],
      panelStyle: { height: document.documentElement.clientHeight * 0.9 - 200 + 'px', overflow: 'auto' },
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '记录人', prop: 'recordUser' },
          { label: '内容', prop: 'recordContent' },
          { label: '时间', prop: 'recordTime' }
        ]
      },
      recordList: null
    }
  },
  mounted() {
    this.preImgList.push(this.dataInfo.skmImgUrl)
    this.preImgList.push(this.dataInfo.xcmImgUrl)
    this.getRecordList()
  },
  methods: {
    getRecordList() {
      this.$lgh.get('api/enterprise/advice/recordlist', { adviceId: this.dataInfo.adviceId }).then(res => {
        this.recordList = res
      })
    }
  }
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
