const obj = {
  siteCode: process.env.VUE_APP_SITE_CODE,
  siteName: process.env.VUE_APP_SITE_Name,
  screenWidth() {
    return document.documentElement.clientWidth
  },
  screenHeight() {
    return document.documentElement.clientHeight
  },
  getToken() {
    return this.getStore('accesstoken', true)
  },
  getStore(key, isStr) {
    if (this.siteCode) {
      key = this.siteCode + key
    }
    const r = localStorage.getItem(key)
    if (isStr) return r
    if (!r) return r
    if (r.indexOf('{') === 0 || r.indexOf('[') === 0) {
      try {
        return JSON.parse(r)
      } catch (e) {
        return r
      }
    } else {
      return r
    }
  },
  setStore(key, value) {
    if (this.siteCode) {
      key = this.siteCode + key
    }
    if (!value || typeof value === 'string') {
      localStorage.setItem(key, value)
    } else {
      localStorage.setItem(key, JSON.stringify(value))
    }
  },
  removeStore(key) {
    if (this.siteCode) {
      key = this.siteCode + key
    }
    localStorage.removeItem(key)
  },
  limitTo: (value, length) => {
    if (!value) return value
    return value.substring(0, length)
  },
  getDate(number, date) {
    let dt = new Date()
    if (date) {
      if (typeof date === 'string') {
        dt = new Date(date)
      } else {
        dt = date
      }
    }
    if (number) {
      dt = new Date(dt.getTime() + 24 * 60 * 60 * 1000 * number)
    }
    var _month = dt.getMonth() + 1
    var strmonth = _month.toString()
    if (_month <= 9) {
      strmonth = '0' + strmonth
    }
    var _day = dt.getDate()
    var strday = _day.toString()
    if (_day <= 9) {
      strday = '0' + strday
    }
    return dt.getFullYear() + '-' + strmonth + '-' + strday
  },
  asyncLoadJS(url) {
    return new Promise((resolve, reject) => {
      const srcArr = document.getElementsByTagName('script')
      let hasLoaded = false
      for (let i = 0; i < srcArr.length; i++) {
        // 判断当前js是否加载上
        hasLoaded = srcArr[i].src === url
      }
      if (hasLoaded) {
        resolve()
        return
      }
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      document.body.appendChild(script)
      script.onload = () => {
        resolve()
      }
      script.onerror = (e) => {
        reject(e)
      }
    })
  },
  stripeTableHeight(option) {
    var _option = Object.assign(
      {
        phName: 'hTable',
        hasDivider: true,
        hasPager: true,
        offset: 0
      },
      option
    )
    var arr = document.getElementsByName(_option.phName)
    var h1 = 0
    arr.forEach((p) => (h1 += p.offsetHeight))
    return document.documentElement.clientHeight - 60 - 16 - 42 - 40 - h1 + 'px'
  }
}
export default obj
