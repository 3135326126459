<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <div>
      <el-card class="margin-xs">
        <table class="table-detail">
          <tr>
            <th>车牌号</th>
            <td>{{ dataInfo.carNo }}</td>
            <th>车架号</th>
            <td>{{ dataInfo.vin }}</td>
          </tr>
          <tr>
            <th>检查人</th>
            <td>{{ dataInfo.inspectUserName }}</td>
            <th>检查时间</th>
            <td>{{ dataInfo.inspectTime }}</td>
          </tr>
          <tr>
            <th>备注</th>
            <td colspan="3">{{ dataInfo.desc }}</td>
          </tr>
        </table>
      </el-card>
    </div>
    <el-row>
      <el-col>
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>检查</span>
            </div>
          </template>
          <el-row>
            <el-col v-for="(item, index) in dataInfo.imgList" :key="index" :span="6">
              <el-card class="padding0 margin-mini" style="line-height:0px;">
                <el-image style="width: 100%; height: 190px" :src="item" :preview-src-list="dataInfo.imgList"> </el-image>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      imgList: null,
      preImgList: []
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped></style>
