<template>
  <el-upload class="l-upload" :action="actionUrl" :data="data" :headers="headers" :before-upload="beforeUpload" :show-file-list="showFileList" :on-success="handleSuccess" :on-error="handleError">
    <slot></slot>
  </el-upload>
</template>

<script>
import { dealResponse } from '../../../utils/ajax'
import utility from '../../../utils/utility'

import { Loading } from 'element-ui'
var loadingInstance = null
export default {
  name: 'LUpload',
  props: {
    url: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    showFileList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actionUrl: process.env.VUE_APP_API_URL + 'api/file/upload',
      headers: { 'Access-Token': utility.getToken() }
    }
  },
  mounted() {
    if (this.url) {
      if (this.url.indexOf('http') !== -1) {
        this.actionUrl = this.url
      } else {
        this.actionUrl = process.env.VUE_APP_API_URL + this.url
      }
    }
  },
  methods: {
    handleSuccess(res, file) {
      console.log('handleSuccess', res)
      loadingInstance && loadingInstance.close()
      dealResponse(res, data => {
        this.$emit('success', data)
      })
    },
    handleError(res, file) {
      loadingInstance && loadingInstance.close()
      this.$lgh.toastError('上传失败')
      console.log('handleError', res)
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      // const isPNG = file.type === 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2

      // if (!isJPG && !isPNG) {
      //   this.$message.error('上传头像图片只能是 JPG,PNG 格式!')
      //   return false
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      //   return false
      // }
      loadingInstance = Loading.service({
        fullscreen: true,
        text: '上传中',
        background: 'rgba(0,0,0,.1)'
      })
      return true
    }
  },
  watch: {
    modelVal(value) {
      this.imageUrl = value
    }
  }
}
</script>

<style>
.l-upload {
  display: inline-block;
}
</style>
