<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-row v-if="dataInfo">
      <el-col class="padding-xs">
        <el-card>
          <l-preview :data="dataInfo" :span="24" :columns="dataColumns"> </l-preview>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataColumns: [
        { label: '所属企业', prop: 'enterpriseName' },
        { label: '姓名', prop: 'userName' },
        { label: '联系电话', prop: 'mobile' },
        { label: '部门', prop: 'departName' },
        { label: '身份证号码', prop: 'identityNo' },
        { label: '是否本地籍', prop: 'isNJNative', filter: a => (a ? '本地籍' : '外地籍') },
        { label: '离宁日期', prop: 'leaveTime', filter: a => this.$lgh.limitTo(a, 10) },
        { label: '离宁事由', prop: 'leaveReason' },
        { label: '离宁方式', prop: 'leaveTraffic' },
        { label: '离宁行程', prop: 'leaveTrip' },
        { label: '离宁交通信息', prop: 'leaveTrafficNo' },
        { label: '返宁时间', prop: 'backTime', filter: a => this.$lgh.limitTo(a, 10) },
        { label: '从何处返宁', prop: 'backFrom' },
        { label: '返宁方式', prop: 'backTraffic' },
        { label: '返宁交通信息', prop: 'backTrafficNo' },
        { label: '在宁居住地点', prop: 'address' },
        { label: '备注', prop: 'remarks' }
      ]
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style scoped></style>
