<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="90%">
    <el-tabs type="border-card" :value="tabValue" @tab-click="tabClick">
      <el-tab-pane name="base" label="车辆基本" :style="panelStyle">
        <car-base :dataInfo="dataInfo"></car-base>
      </el-tab-pane>
      <el-tab-pane name="gps" label="车辆轨迹" :style="panelStyle">
        <car-gps ref="cargps" v-if="gpsVisible" :dataInfo="dataInfo" :option="option"></car-gps>
      </el-tab-pane>
      <el-tab-pane name="fence" label="围栏警报" :style="panelStyle">
        <fence-warn :dataInfo="dataInfo" @action="doAction"></fence-warn>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import carBase from './base'
import carGps from './gps'
import fenceWarn from './fence_warn'
export default {
  components: { carBase, carGps, fenceWarn },
  data() {
    return {
      dataInfo: null,
      option: null,
      tabValue: 'base',
      gpsVisible: false,
      panelStyle: { height: document.documentElement.clientHeight * 0.9 - 180 + 'px', overflow: 'auto' }
    }
  },
  mounted() {
    if (this.option) {
      if (this.option.type === 'GPSTrack') {
        this.showGps()
      }
    }
  },
  methods: {
    tabClick(e) {
      if (e.name === 'gps') this.gpsVisible = true
    },
    doAction(option) {
      if (option.type === 'GPSTrack') {
        // this.showGps()
        // debugger
        // this.$refs.cargps.initDate(option.start, option.end)
      }
    },
    showGps() {
      this.tabValue = 'gps'
      this.gpsVisible = true
    }
  }
}
</script>

<style scoped>
.el-tabs__content {
  padding: 0;
}
</style>
