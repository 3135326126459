import router from './router'
import store from './store'
import utility from './lgh/utils/utility'
import msg from '@/lgh/utils/msg'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const whiteList = ['/login', '/dingtalk_org/connect', '/dingtalk_isv/connect']

router.beforeEach((to, from, next) => {
  if (to) {
    store.commit('SET_CURRENT_MENUS', to.name)
  }
  NProgress.start()
  if (!utility.getToken()) {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  } else {
    /* has token */
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      store.dispatch('initData').then(() => {
        if (store.state.userInfo == null) {
          // 判断当前用户是否已拉取完user_info信息
          store
            .dispatch('GetUserInfo')
            .then((res) => {
              if (res.userinfo == null) {
                store.dispatch('FedLogOut').then(() => {
                  msg.toastError('登录过期,请重新登录')
                  next({ path: '/' })
                })
              } else {
                store.dispatch('GenerateRoutes', res.menulist).then((accessRoutes) => {
                  router.addRoutes(accessRoutes)
                  // accessRoutes.forEach(a => {
                  //   router.addRoute(a) // 动态添加可访问路由表
                  // })
                  next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
                })
              }
            })
            .catch(() => {
              store.dispatch('FedLogOut').then(() => {
                // msg.toastError(err)
                next({ path: '/' })
              })
            })
        } else {
          next()
        }
      })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
