<template>
  <el-header>
    <el-row type="flex" justify="space-between" align="middle" style="height:100%;user-select: none;">
      <el-col :span="10" class="flex align-center">
        <!-- <img src="@/assets/logo.png" @click="toggleMenu()" style="height:50px;" /> -->
        <img :src="$store.state.setting.site_head_logo" @click="toggleMenu()" style="height:50px;border-radius: 20px;" />
        <span class="text-white text-sm padding-left-xs" @click="toggleMenu()">{{ $store.state.setting.site_title }}</span>
      </el-col>
      <el-col :span="5" class="flex justify-end align-center">
        <div><img :src="$store.state.setting.site_user_logo" class="margin-right-xs" style="height:40px;border-radius: 10px;" /></div>
        <!-- <el-avatar :size="30" icon="el-icon-user-solid" class="margin-right-xs"></el-avatar> -->
        <el-dropdown trigger="click" @command="handleCommand" v-if="$store.state.userInfo">
          <span class="el-dropdown-link text-white cursor">
            {{ $store.state.userInfo.fullName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="changepassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-header>
</template>

<script>
import { mapState } from 'vuex'
import changepassword from '../changepassword'
export default {
  name: 'LayoutHeader',
  computed: {
    ...mapState(['userInfo'])
  },
  props: {},
  data() {
    return {}
  },
  methods: {
    toggleMenu() {
      // this.$store.commit('SET_TOGGLE_MENU', !this.$store.state.toggleMenu)
    },
    async handleCommand(command) {
      if (command === 'changepassword') {
        await this.$lgh.openDialog(
          {
            ins: changepassword,
            title: '修改密码'
          },
          this
        )
        this.$lgh.toastSuccess('密码修改成功')
        this.logout()
      } else if (command === 'logout') {
        this.logout()
      }
    },
    logout() {
      this.$store.dispatch('Logout').then(res => {
        this.$router.replace({ path: '/login' })
      })
    }
  }
}
</script>
<style lang="less"></style>
