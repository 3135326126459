<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-row v-if="dataInfo">
      <el-col class="padding-xs padding-bottom-0">
        <el-card>
          <div slot="header" class="clearfix">
            <span>基本信息</span>
          </div>
          <l-preview :data="dataInfo" :span="12" :columns="dataColumns">
            <template slot="borrowState" slot-scope="{ value }">
              <span v-if="value == 0" class="text-blue">未提交</span>
              <span v-else-if="value == 1" class="text-orange">已提交</span>
              <span v-else-if="value == 10" class="text-green">已审批</span>
            </template>
          </l-preview>
        </el-card>
      </el-col>
      <el-col class="padding-xs" :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <span>途径地</span>
          </div>
          <div class="flex align-center padding-bottom-xs padding-lr-xs" v-for="(item, index) in dataInfo.posList" :key="index">
            <div class="text-left text-bold padding-right-xs">
              {{ item.tag }}
            </div>
            <div class="flex-sub text-right">
              {{ item.place }}
            </div>
          </div>
          <!-- <div class="flex align-center padding-bottom-xs padding-lr-xs">
            <div class="text-left text-bold padding-right-xs">
              驾车里程
            </div>
            <div class="flex-sub text-right">{{ dataInfo.posDistance }}公里</div>
          </div> -->
        </el-card>
      </el-col>
      <el-col class="padding-xs" :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <span>费用信息</span>
          </div>
          <div class="flex align-center padding-bottom-xs padding-lr-xs" v-for="(item, index) in dataInfo.feeList" :key="index">
            <div class="text-left text-bold padding-right-xs">
              {{ item.feeName }}
            </div>
            <div class="flex-sub text-right">{{ item.fee }}元</div>
          </div>
        </el-card>
      </el-col>
      <el-col class="padding-xs" :span="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>相关图片</span>
          </div>
          <el-row>
            <el-col v-for="(item, index) in allImgList" :key="index" :span="4">
              <el-card class="padding0 margin-mini" style="line-height:0px; text-align:center;">
                <el-image style="max-width: 100%; height: 80px" :src="item.url" @click="$site.view_file(item.url)"> </el-image>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col class="padding-xs" :span="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>日志记录</span>
          </div>
          <p v-if="dataInfo.recordList.length == 0" class="padding text-center text-gray">
            暂无记录~
          </p>
          <el-row>
            <el-col :span="12" v-for="(item, index) in dataInfo.recordList" :key="index" class="padding-xs">
              <el-card>
                <div style="height:200px;">
                  <div v-if="item.recordType == 'text'" style="height:100%;overflow:auto;">
                    <span>{{ item.recordContent }}</span>
                  </div>
                  <div v-if="item.recordType == 'img'" style="height:100%;overflow:auto;">
                    <el-image style="max-height:100%;max-width:100%;" :src="item.recordContent" :preview-src-list="[item.recordContent]"> </el-image>
                  </div>
                  <div v-if="item.recordType == 'audio'" style="height:100%;overflow:auto;display:flex;align-items:center;justify-content:center;">
                    <audio :src="item.recordContent" controls style="width:80%;display:block;">
                      您的浏览器不支持 音频。
                    </audio>
                  </div>
                  <div v-if="item.recordType == 'video'" style="height:100%;overflow:hidden;">
                    <video :src="item.recordContent" controls muted style="height:100%;width:100%;">
                      您的浏览器不支持 视频。
                    </video>
                  </div>
                </div>
                <el-divider></el-divider>
                <div class="flex justify-between align-center padding-xs">
                  <el-tag type="primary">{{ index + 1 }}</el-tag>
                  <p>
                    <i class="el-icon-time"></i>
                    {{ item.recordTime }}
                  </p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataColumns: [
        { label: '车牌号', prop: 'carNo' },
        { label: '审核状态', slot: 'borrowState' },
        { label: '用车人', prop: 'userName' },
        { label: '审核人', prop: 'verifyUserName' },
        { label: '用车事由', prop: 'workType' },
        { label: '审核时间', prop: 'verifyTime' },
        { label: '预计出发时间', prop: 'startTime' },
        { label: '预计到达时间', prop: 'endTime' },
        { label: '出发里程数', prop: 'startMileage' },
        { label: '返回里程数', prop: 'endMileage' },
        {
          label: '表显里程数',
          prop: 'endMileage',
          filter: (a, data) => {
            if (data.startMileage && data.endMileage) return data.endMileage - data.startMileage
            else return '---'
          }
        },
        { label: '相关说明', prop: 'desc' }
      ],
      allImgList: []
    }
  },
  mounted() {
    if (this.dataInfo.startImgUrl) {
      this.allImgList.push({ url: this.dataInfo.startImgUrl, text: '出发里程表' })
    }
    if (this.dataInfo.endImgUrl) {
      this.allImgList.push({ url: this.dataInfo.endImgUrl, text: '返回里程表' })
    }

    this.dataInfo.imgList.forEach((a, i) => {
      this.allImgList.push({ url: a, text: '相关图片' + (i + 1) })
    })
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style scoped></style>
