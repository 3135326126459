<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh">
    <el-card class="margin-xs">
      <l-description :option="descOption" :data="descData">
        <template slot="frksFlag">
          <span v-if="dataInfo.frksFlag == 0">无</span>
          <span v-else-if="dataInfo.frksFlag == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="workFlag">
          <span v-if="dataInfo.workFlag == 0">未上班</span>
          <span v-else-if="dataInfo.workFlag == 1">上班</span>
        </template>
        <template slot="temp">
          <span v-if="!dataInfo.temp" class="text-red">未上报</span>
          <span v-if="dataInfo.temp < 37.2">{{ dataInfo.temp }}℃</span>
          <span v-else class="text-red text-bold">{{ dataInfo.temp }}℃</span>
        </template>
        <template slot="isExist">
          <span v-if="dataInfo.isExist == 0">无</span>
          <span v-else-if="dataInfo.isExist == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isDangerArea">
          <span v-if="dataInfo.isDangerArea == 0">无</span>
          <span v-else-if="dataInfo.isDangerArea == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isHS48">
          <span v-if="dataInfo.isHS48 == 0" class="text-red text-bold">无</span>
          <span v-else-if="dataInfo.isHS48 == -1">不需要</span>
          <span v-else-if="dataInfo.isHS48 == 1">有</span>
        </template>
        <template slot="skmColor">
          <div class="flex align-center justify-start">
            <span v-if="dataInfo.skmColor === 'G'" class="bg-green shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.skmColor === 'Y'" class="bg-yellow shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.skmColor === 'R'" class="bg-red shadow" style="padding:10px 30px 10px 30px;"></span>
          </div>
        </template>
        <template slot="xcmColor">
          <div class="flex align-center justify-start">
            <span v-if="dataInfo.xcmColor === 'G'" class="bg-green shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.xcmColor === 'Y'" class="bg-yellow shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.xcmColor === 'R'" class="bg-red shadow" style="padding:10px 30px 10px 30px;"></span>
          </div>
        </template>
        <template slot="img" slot-scope="scope">
          <div class="flex justify-center">
            <el-image style="width:80%;" :src="dataInfo[scope.prop]" :preview-src-list="preList"> </el-image>
          </div>
        </template>
      </l-description>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      descOption: {
        column: 3,
        labelStyle: {
          width: '100px'
        }
      },
      descData: null,
      preList: []
    }
  },
  mounted() {
    this.descData = [
      { label: '上报人', text: this.dataInfo.userName },
      { label: '联系电话', text: this.dataInfo.userMobile },
      { label: '所属部门', text: this.dataInfo.departName },
      { label: '日报日期', text: this.dataInfo.reportDate },
      { label: '是否上班', slot: 'workFlag' },
      { label: '是否发热咳嗽', slot: 'frksFlag' },
      { label: '近期是否出入宁', slot: 'isExist' },
      { label: '是否经过中高风险地区', slot: 'isDangerArea' },
      { label: '体温', slot: 'temp' },
      { label: '行程码颜色', slot: 'xcmColor' },
      { label: '苏康码颜色', slot: 'skmColor' },
      { label: '48小时核酸', slot: 'isHS48' },
      { label: '备注信息', text: this.dataInfo.remark, span: 3 },
      { label: '苏康码图片', slot: 'img', prop: 'skmImgUrl' },
      { label: '行程码图片', slot: 'img', prop: 'xcmImgUrl' },
      { label: '核酸检测图片', slot: 'img', prop: 'hsjcImgUrl' }
    ]
    this.preList = [this.dataInfo.skmImgUrl, this.dataInfo.xcmImgUrl, this.dataInfo.hsjcImgUrl]
  },
  methods: {
    getRecordList() {
      this.$lgh.get('api/enterprise/advice/recordlist', { adviceId: this.dataInfo.adviceId }).then(res => {
        this.recordList = res
      })
    }
  }
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
