const importImage = (path, handle) => {
  import('@/assets/' + path).then(a => {
    handle(a.default)
  })
}

var imageData = {}
var arr1 = ['A', 'B', 'C']
var arr2 = [0, 1, 2, 3, 4, 5]
arr1.forEach(a => {
  arr2.forEach(b => {
    importImage('image/map/shop_' + a + '_' + b + '.png', r => {
      imageData['shop' + a + b] = r
    })
  })
})
arr2.forEach(b => {
  importImage('image/map/car_' + b + '.png', r => {
    imageData['car' + b] = r
  })
})
var arr3 = [0, 1, 2, 3]
arr3.forEach(b => {
  importImage('image/map/inspect_' + b + '.png', r => {
    imageData['inspect' + b] = r
  })
})
const result = {
  getCarIcon(state) {
    return imageData['car' + state]
  }
}
export default result
