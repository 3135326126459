<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="90%">
    <el-tabs type="border-card" value="base">
      <el-tab-pane name="base" label="建议详细" :style="panelStyle">
        <el-row>
          <el-col :span="12">
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>基本信息</span>
                </div>
              </template>
              <table class="table-detail">
                <tr>
                  <th>建议项目</th>
                  <td colspan="3">{{ dataInfo.adviceTitle }}</td>
                </tr>
                <tr>
                  <th>建议类型</th>
                  <td>{{ dataInfo.adviceType }}</td>
                  <th>建议分类</th>
                  <td>{{ dataInfo.adviceCategory }}</td>
                </tr>
                <tr>
                  <th>建议人</th>
                  <td>{{ dataInfo.createUserName }}</td>
                  <th>提出部门</th>
                  <td>{{ dataInfo.createDepartName }}</td>
                </tr>
                <tr>
                  <th>提出时间</th>
                  <td>{{ dataInfo.submitTime }}</td>
                  <th>建议状态</th>
                  <td class="text-bold">
                    <span v-if="dataInfo.adviceStatus == 0" class="text-grey">未提交</span>
                    <span v-else-if="dataInfo.adviceStatus == 1" class="text-blue">已提交</span>
                    <span v-else-if="dataInfo.adviceStatus == 10" class="text-green">已入围</span>
                    <span v-else-if="dataInfo.adviceStatus == 9" class="text-red">无效建议</span>
                  </td>
                </tr>
              </table>
            </el-card>
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>存在问题</span>
                </div>
              </template>
              <div class="padding-xs">{{ dataInfo.adviceContent }}</div>
              <div>
                <el-col :span="12" v-for="(item, index) in dataInfo.fileAList" :key="index">
                  <p class="text-cut flex align-center padding-mini fileitem">
                    <img :src="item.iconUrl" class="fileIcon" /><a @click="$site.view_file(item)">{{ item.name }}</a>
                  </p>
                </el-col>
              </div>
            </el-card>
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>改进措施</span>
                </div>
              </template>
              <div class="padding-xs">{{ dataInfo.adviceMethod }}</div>
              <div>
                <el-col :span="12" v-for="(item, index) in dataInfo.fileBList" :key="index">
                  <p class="text-cut flex align-center padding-mini fileitem">
                    <img :src="item.iconUrl" class="fileIcon" /><a @click="$site.view_file(item)">{{ item.name }}</a>
                  </p>
                </el-col>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>审核信息</span>
                </div>
              </template>
              <table class="table-detail">
                <tr>
                  <th>审核人</th>
                  <td>{{ dataInfo.verifyUserName }}</td>
                  <th>审核部门</th>
                  <td>{{ dataInfo.verifyDepartName }}</td>
                </tr>
                <tr>
                  <th>审核时间</th>
                  <td>{{ dataInfo.verifyTime }}</td>
                  <th>审核结果</th>
                  <td class="text-bold">
                    <p v-if="dataInfo.adviceStatus == 1">
                      已提交-
                      <span v-if="dataInfo.verifyHistoryIds" class="text-cyan">已转移</span>
                      <span v-else class="text-gray">未审核</span>
                    </p>
                    <p v-else-if="dataInfo.adviceStatus == 9" class="text-red">无效建议</p>
                    <p v-else-if="dataInfo.adviceStatus == 10" class="text-green">
                      已入围-
                      <span v-if="dataInfo.effectStatus == 1" class="text-cyan">实施中</span>
                      <span v-else-if="dataInfo.effectStatus == 8" class="text-red">无法实施</span>
                      <span v-else-if="dataInfo.effectStatus == 9" class="text-red">暂缓实施</span>
                      <span v-else-if="dataInfo.effectStatus == 2" class="text-green">实施完成</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>审核说明</th>
                  <td colspan="3">{{ dataInfo.unableDesc || dataInfo.transferDesc }}</td>
                </tr>
              </table>
            </el-card>

            <el-card class="margin-xs" v-if="dataInfo.effectStatus == 1 || dataInfo.effectStatus == 2">
              <template #header>
                <div class="card-header">
                  <span>实施信息</span>
                </div>
              </template>
              <table class="table-detail">
                <tr>
                  <th>要求时间</th>
                  <td>{{ dataInfo.effectRequestDate }}</td>
                  <th>实施要求</th>
                  <td>{{ dataInfo.effectRequestDesc }}</td>
                </tr>
                <tr>
                  <th>实施人</th>
                  <td>{{ dataInfo.effectUserName }}</td>
                  <th>实施部门</th>
                  <td>{{ dataInfo.effectDepartName }}</td>
                </tr>
                <tr>
                  <th>实施时间</th>
                  <td>{{ dataInfo.effectTime }}</td>
                  <th>实施状态</th>
                  <td class="text-bold">
                    <span v-if="dataInfo.effectStatus == 0" class="text-gray">未实施</span>
                    <span v-else-if="dataInfo.effectStatus == 1" class="text-blue">实施中</span>
                    <span v-else-if="dataInfo.effectStatus == 2" class="text-green">实施完成</span>
                  </td>
                </tr>
                <tr>
                  <th>实施说明</th>
                  <td colspan="3">{{ dataInfo.effectContent }}</td>
                </tr>
                <tr>
                  <th>实施图片</th>
                  <td colspan="3">
                    <el-col v-for="(item, index) in dataInfo.effectImgList" :key="index" :span="6">
                      <el-card class="padding0 margin-mini" style="line-height:0px;">
                        <el-image style="width: 100%; height: 80px" :src="item" :preview-src-list="dataInfo.effectImgList"> </el-image>
                      </el-card>
                    </el-col>
                  </td>
                </tr>
                <tr v-if="dataInfo.fileCList && dataInfo.fileCList.length !== 0">
                  <th>相关文件</th>
                  <td colspan="3">
                    <el-col :span="12" v-for="(item, index) in dataInfo.fileCList" :key="index">
                      <p class="text-cut flex align-center padding-mini fileitem">
                        <img :src="item.iconUrl" class="fileIcon" /><a @click="$site.view_file(item)">{{ item.name }}</a>
                      </p>
                    </el-col>
                  </td>
                </tr>
              </table>
            </el-card>

            <el-card class="margin-xs" v-if="dataInfo.evaluateLevel">
              <template #header>
                <div class="card-header">
                  <span>建议评价</span>
                </div>
              </template>

              <table class="table-detail">
                <tr>
                  <th>评价等级</th>
                  <td>{{ dataInfo.evaluateLevel }}</td>
                  <th>节约成本</th>
                  <td>{{ dataInfo.costSaving }}元</td>
                </tr>
                <tr>
                  <th>奖励金额</th>
                  <td>{{ dataInfo.evaluateMoney }}元</td>
                  <th>奖励发放</th>
                  <td class="text-bold">
                    <span v-if="dataInfo.evaluateMoneyState == 0" class="text-gray">未发放</span>
                    <span v-else-if="dataInfo.evaluateMoneyState == 1" class="text-green">已发放</span>
                  </td>
                </tr>
                <tr>
                  <th>评价说明</th>
                  <td colspan="5">{{ dataInfo.evaluateDesc }}</td>
                </tr>
              </table>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="record" label="操作日志" :style="panelStyle">
        <l-table :option="tableOption" :data="recordList">
          <template slot="itemState" slot-scope="scope">
            <span v-if="scope.row.dealState == 0" class="text-gray">未检测</span>
            <span v-else-if="scope.row.itemState == 1" class="text-green">完好</span>
            <span v-else-if="scope.row.itemState == 2" class="text-red">缺损</span>
          </template>
          <template slot="fee" slot-scope="scope">
            <span v-if="scope.row.dealState == 0" class="text-gray">---</span>
            <span v-else-if="scope.row.itemState == 1" class="text-green">---</span>
            <span v-else-if="scope.row.itemState == 2" class="text-red">{{ scope.row.fee }}</span>
          </template>
        </l-table>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="print">打印</el-button>
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      imgList: null,
      preImgList: [],
      panelStyle: { height: document.documentElement.clientHeight * 0.9 - 200 + 'px', overflow: 'auto' },
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '记录人', prop: 'recordUser' },
          { label: '内容', prop: 'recordContent' },
          { label: '备注', prop: 'recordDesc' },
          { label: '时间', prop: 'recordTime' }
        ]
      },
      recordList: null
    }
  },
  mounted() {
    this.getRecordList()
  },
  methods: {
    getRecordList() {
      this.$lgh.get('api/enterprise/advice/recordlist', { adviceId: this.dataInfo.adviceId }).then(res => {
        this.recordList = res
      })
    },
    print() {
      this.$site.view_advice_Print(this.dataInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
