<template>
  <div>
    <div class="l-table-top">
      <div class="left">
        <slot name="left"></slot>
      </div>
      <div class="right">
        <el-popover placement="bottom" width="100" trigger="click">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-divider> </el-divider>
          <el-checkbox-group :min="1" v-model="selColumn" @change="handleCheckedChange">
            <el-checkbox v-for="(item, index) in tableColumns" :label="item.code" :key="index" class="padding-mini">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference" icon="el-icon-setting">显示列</el-button>
        </el-popover>
      </div>
    </div>
    <slot :option="tableOption"></slot>
  </div>
</template>

<script>
export default {
  name: 'LTableTop',
  props: {
    option: {
      type: Object,
      default: null
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    startIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      tableOption: null,
      tableColumns: null,
      selColumn: []
    }
  },
  mounted() {
    this.tableOption = this.option
    this.tableColumns = this.option.columns

    this.option.columns.forEach(a => {
      if (!a.code) {
        a.code = a.prop || a.slot || a.type
      }
      if (a.selected !== false) {
        a.selected = true
      }
      if (a.selected) {
        this.selColumn.push(a.code)
      }
    })
    this.setColumns()
  },
  methods: {
    setColumns() {
      this.tableOption.columns = this.tableColumns.filter(a => this.selColumn.includes(a.code))
    },
    cellClick(row, column, cell, event) {
      this.$refs.columnTable.toggleRowSelection(row)
    },
    handleCheckedChange() {
      this.isIndeterminate = this.selColumn.length !== this.tableColumns.length
      this.checkAll = this.selColumn.length === this.tableColumns.length
      this.setColumns()
    },
    handleCheckAllChange(val) {
      if (val) {
        this.selColumn = this.tableColumns.map(a => a.code)
      } else {
        this.selColumn = [this.tableColumns[0].code]
      }
      this.isIndeterminate = false
      this.setColumns()
    }
  },
  watch: {}
}
</script>
<style scoped lang="less">
.l-table-top {
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    flex: 1;
  }
}
</style>
