<template>
  <el-aside class="el-scrollbar layout-silde" :width="$store.state.toggleMenu ? '60px' : '210px'" :style="asideStyle">
    <el-menu
      unique-opened
      :default-active="$store.state.currentMenu"
      @open="menuOpen"
      @close="menuClose"
      :collapse="$store.state.toggleMenu"
      background-color="#222233"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="index" @click="menuClick({ fullPath: '/index' })">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu v-for="(item, index) in menuList" :key="index" :index="item.code">
        <template slot="title">
          <i :class="item.iconClass"></i>
          <span>{{ item.name }}</span>
        </template>
        <template v-for="(item2, index2) in item.children">
          <el-menu-item v-if="!item2.children" :key="index2" :index="item2.code" @click="menuClick(item2)">
            <i class="el-icon-location"></i>
            {{ item2.name }}
          </el-menu-item>

          <el-submenu v-else :key="index2" :index="item2.code">
            <template slot="title">
              <i :class="item2.iconClass"></i>
              <span>{{ item2.name }}</span>
            </template>
            <el-menu-item v-for="(item3, index3) in item2.children" :key="index3" :index="item3.code" @click="menuClick(item3)">
              <i class="el-icon-location"></i>
              {{ item3.name }}
            </el-menu-item>
          </el-submenu>
        </template>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: 'LayoutAside',
  props: {},
  data() {
    return {
      menuList: this.$store.state.menuList,
      currentMenuCode: this.$router.currentRoute.name,
      asideStyle: {
        height: document.documentElement.clientHeight - 60 + 'px',
        overflow: 'auto'
      }
    }
  },
  created() {},
  methods: {
    test1(item, index) {
      this.menuIndex = index
    },
    menuOpen(e) {},
    menuClose(e) {},
    menuClick(item) {
      this.$router.push({ path: item.fullPath, params: item.params })
    }
  }
}
</script>
<style lang="less"></style>
