<template>
  <div class="l-panel" :class="[panelState, type]" :style="[{ zIndex: this.zIndex }]">
    <el-card class="box-card" :shadow="shadow">
      <div slot="header" class="clearfix flex align-center justify-center">
        <span @click="handleHeaderClick()">{{ title }}</span>
        <div class="flex-sub">
          <slot name="header" :state="panelState"></slot>
        </div>
        <p class="padding-lr-xs" v-if="expand" @click="handleHeaderClick()">
          <span class="text-blue" :class="panelState == 'show' ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></span>
        </p>
      </div>
      <div class="body" :style="bodyStyle">
        <slot></slot>
        <!-- <l-table :option="tableOption" :data="employeeList"> </l-table> -->
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'LPanel',
  props: {
    shadow: {
      type: String,
      default: 'always'
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    expand: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: 'show'
    },
    zIndex: {
      type: Number,
      default: 500
    },
    height: {
      type: [String, Number],
      default: null
    },
    maxHeight: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      panelState: 'show',
      panelStyle: null,
      bodyStyle: {}
    }
  },
  mounted () {
    this.panelState = this.state
    if (this.height !== null) {
      this.bodyStyle.height = this.height + 'px'
    }
    if (this.maxHeight !== null) {
      this.bodyStyle['max-height'] = this.maxHeight + 'px'
    }
  },
  methods: {
    handleHeaderClick () {
      if (this.expand) {
        if (this.panelState === 'show') {
          this.panelState = 'hide'
        } else {
          this.panelState = 'show'
        }
      }
    }
  }
}
</script>

<style lang="less">
.l-panel {
  transition: all ease-in-out 0.3s;
  .el-card__header {
    cursor: pointer;
    padding: 10px 12px;
  }
  .body {
    overflow: auto;
  }
}
.l-panel.mini {
  .el-card__header {
    padding: 5px 10px;
  }
}
.l-panel.hide {
  .el-card__body {
    padding: 0;
  }
  .body {
    display: none;
  }
}
</style>
