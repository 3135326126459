import constantBase from './_constant_base'
import constantMap from './_constant_map'

var _limitTo = (value, length) => {
  if (!value) return value
  return value.substring(0, length)
}

const constant = {
  employeeMTypeList: [
    { code: 'formal', text: '正式员工' },
    { code: 'external', text: '外雇员工' }
  ],
  employeeIsQuitList: [
    { code: 'false', text: '在职' },
    { code: 'true', text: '离职' }
  ],
  employeeIsLeaderList: [
    { code: 'false', text: '非部门领导' },
    { code: 'true', text: '部门领导' }
  ],
  employeeIsSeniorList: [
    { code: 'false', text: '非公司高管' },
    { code: 'true', text: '公司高管' }
  ],
  easset_state_list: [
    { code: 1, text: '在建' },
    { code: 2, text: '启用' },
    { code: 3, text: '封存' },
    { code: 4, text: '处置' },
    { code: 5, text: '报废' }
  ],
  easset_usestate_list: [
    { code: 1, text: '闲置' },
    { code: 2, text: '使用中' },
    { code: 3, text: '借出' },
    { code: 4, text: '租出' }
  ],
  easset_purchaseType_list: [
    { code: 1, text: '采购' },
    { code: 2, text: '租赁' },
    { code: 10, text: '其他' }
  ],
  easset_unit_list: [
    { code: '台', text: '台' },
    { code: '个', text: '个' },
    { code: '套', text: '套' },
    { code: '只', text: '只' },
    { code: '辆', text: '辆' },
    { code: '量', text: '量' },
    { code: '平方米', text: '平方米' }
  ],
  carSourceList: [
    { code: '0', text: '自购' },
    { code: '1', text: '租赁' },
    { code: '2', text: '调拨' },
    { code: '3', text: '试用' },
    { code: '4', text: '加盟' }
  ],
  carStatusList: [
    { code: '0', text: '未上牌' },
    { code: '1', text: '已上牌' },
    // { code: '2', text: '租出' },
    // { code: '3', text: '闲置' },
    // { code: '4', text: '借用' },
    { code: '5', text: '停用' },
    { code: '6', text: '售出' },
    { code: '7', text: '报废' }
  ],
  useStatusList: [
    { code: '0', text: '闲置' },
    { code: '1', text: '借出' },
    { code: '2', text: '租出' }
  ],
  deviceChargeList: [
    { code: '0', text: '不可充电' },
    { code: '1', text: '可充电' }
  ],
  insuranceTypeList: [
    { code: 'jqx', text: '交强险' },
    { code: 'syx', text: '商业险' }
  ],
  borrowStateList: [
    { code: '0', text: '申请中' },
    { code: '1', text: '已借出' },
    { code: '8', text: '申请归还' },
    { code: '9', text: '拒绝借出' },
    { code: '10', text: '已归还' }
  ],
  myCarBorrowStateList: [
    { code: '0', text: '未提交' },
    { code: '1', text: '已提交' },
    { code: '10', text: '已审批' }
  ],
  adviceStatusList: [
    { code: '0', text: '未提交' },
    { code: '1', text: '已提交' },
    { code: '9', text: '无效建议' },
    { code: '10', text: '已入围' }
  ],
  adviceEffectStatusList: [
    { code: '0', text: '未实施' },
    { code: '1', text: '实施中' },
    { code: '2', text: '已完成' },
    { code: '8', text: '无法实施' },
    { code: '9', text: '暂缓实施' }
  ],
  adviceHasEvaluateList: [
    { code: 'false', text: '未评价' },
    { code: 'true', text: '已评价' }
  ],
  advice_dateType: [
    { code: 'submitTime', text: '提交日期' },
    { code: 'effectTime', text: '实施日期' }
  ],
  safelyMatterStatusList: [
    { code: '0', text: '未提交' },
    { code: '1', text: '已提交' },
    { code: '9', text: '无效' },
    { code: '10', text: '已采纳' }
  ],
  safelyMatterEffectStatusList: [
    { code: '0', text: '未整改' },
    { code: '1', text: '整改中' },
    { code: '2', text: '已完成' },
    { code: '9', text: '临时管控' }
  ],
  safelyMatterCheckStateList: [
    { code: '0', text: '未验审' },
    { code: '100', text: '已验审' }
  ],
  safelyMatter_dateType: [
    { code: 'submitTime', text: '上报日期' },
    { code: 'effectTime', text: '实施日期' }
  ],
  safelyMatter_riskFactorCode: [
    { code: 1, text: '人的因素' },
    { code: 2, text: '物的因素' },
    { code: 3, text: '环境因素' },
    { code: 4, text: '管理因素' }
  ],
  proposalStatusList: [
    { code: '0', text: '未提交' },
    { code: '1', text: '已提交' },
    { code: '2', text: '已入围' },
    { code: '3', text: '已采纳' },
    { code: '-1', text: '无效建议' },
    { code: '-2', text: '无法实施' }
  ],
  proposalCategoryList: ['安全管理', '质量管理', '环境管理', '职业健康', '人事管理', '技术管理', '生产管理', '设备管理', '市场开发', '采购管理', '财务管理', '其他'],
  proposal_dateType: [
    { code: 'createTime', text: '创建日期' },
    { code: 'proposalTime', text: '建议日期' }
  ],
  assetTypeList: [
    { code: 1, text: '固定资产' },
    { code: 2, text: '流动资产' },
    { code: 3, text: '无形资产' },
    { code: 4, text: '长期投资' },
    { code: 5, text: '其他资产' }
  ],
  ecarListColumn: {
    list: [
      { code: 'modelName', label: '品牌型号', prop: 'modelName', overflow: true, visible: true },
      { code: 'carSourceText', label: '车辆来源', prop: 'carSourceText', visible: true },
      { code: 'carStatusText', label: '车辆状态', prop: 'carStatusText', visible: true },
      { code: 'insuranceJQXExpireDate', label: '交强险到期', slot: 'insuranceJQXExpireDate', visible: false },
      { code: 'insuranceSYXExpireDate', label: '商业险到期', slot: 'insuranceSYXExpireDate', visible: false },
      { code: 'yearlyInspectExpireDate', label: '年检到期', slot: 'yearlyInspectExpireDate', visible: false },
      { code: 'deviceNo', label: 'GPS设备', prop: 'deviceNo', visible: false },
      { code: 'gpsTime', label: '定位时间', prop: 'gpsTime', visible: false },
      { code: 'reponsibleUserName', label: '责任人', prop: 'reponsibleUserName', visible: false },
      { code: 'inspectUserName', label: '检查人', prop: 'inspectUserName', visible: false },
      { code: 'inspectTypeText', label: '检查方式', prop: 'inspectTypeText', visible: false },
      { code: 'inspectDuringText', label: '检查周期', prop: 'inspectDuringText', visible: false },
      { code: 'inspectTime', label: '检查时间', prop: 'inspectTime', visible: false, filter: a => _limitTo(a, 10) },
      { code: 'inspectExpireTime', label: '检查到期', prop: 'inspectExpireTime', visible: false, filter: a => _limitTo(a, 10) },
      { code: 'fenceName', label: '电子围栏', prop: 'fenceName', visible: false },
      { code: 'groupName', label: '车辆分组', prop: 'groupName', visible: false },
      { code: 'mileage', label: '当前公里数', prop: 'mileage', visible: false },
      { code: 'registerDate', label: '注册日期', prop: 'registerDate', visible: false, filter: a => _limitTo(a, 10) }
    ],
    get(enterpriseCode, userId) {
      var dbData = localStorage.getItem('ECarListColumn_' + enterpriseCode + '_' + userId)
      var arr = []
      if (dbData) {
        arr = dbData.split(',')
      }
      var returnList = []
      this.list.forEach(a => {
        if (!dbData) {
          returnList.push(Object.assign({}, a))
        } else if (arr.indexOf(a.code) !== -1) {
          returnList.push(Object.assign({}, a, { visible: true }))
        } else {
          returnList.push(Object.assign({}, a, { visible: false }))
        }
      })
      return returnList
    },
    set(enterpriseCode, userId, arr) {
      localStorage.setItem('ECarListColumn_' + enterpriseCode + '_' + userId, arr.join(','))
    }
  }
}
export default Object.assign(constant, constantBase, constantMap)
