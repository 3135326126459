import axios from 'axios'
import utility from './utility.js'
import msg from './msg.js'
import store from '../../store'
import { Loading } from 'element-ui'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const ajax = (option, success, params) => {
  option = Object.assign(
    {
      // axios中请求配置有baseURL选项，表示请求URL公共部分
      baseURL: process.env.VUE_APP_API_URL,
      // 超时
      timeout: 15000,
      headers: { 'Access-Token': utility.getToken() } // 让每个请求携带自定义token 请根据实际情况自行修改
    },
    option
  )
  var defaultParam = {
    loading: true,
    loadingText: '请稍候...',
    fail: null
  }

  if (typeof params === 'boolean') {
    defaultParam.loading = params
  } else if (typeof params === 'function') {
    defaultParam.fail = params
  } else if (typeof params === 'string') {
    defaultParam.loadingText = params
  } else {
    Object.assign(defaultParam, params)
  }

  if (defaultParam.params) {
    option.params = defaultParam.params
  }

  var loadingInstance = null
  if (defaultParam.loading) {
    loadingInstance = Loading.service({
      fullscreen: true,
      text: defaultParam.loadingText,
      background: 'rgba(0,0,0,.1)'
    })
  }
  axios
    .request(option)
    .then(res => {
      loadingInstance && loadingInstance.close()
      console.log(option.url, res.data)
      dealResponse(res.data, success, defaultParam.fail)
    })
    .catch(error => {
      loadingInstance && loadingInstance.close()
      msg.toastError(error)
      defaultParam.fail && defaultParam.fail(error)
    })
}

function createData(data) {
  if (!data) return data
  if (typeof data === 'string') {
    return getFromStoreUser(data)
  } else if (data instanceof Array) {
    const r = {}
    data.forEach(a => {
      if (typeof a === 'string') {
        Object.assign(r, getFromStoreUser(a))
      } else {
        Object.assign(r, a)
      }
    })
    return r
  } else {
    return data
  }
}

function getFromStoreUser(code) {
  const r = {}
  r[code] = store.state.userInfo[code]
  return r
}

export function dealResponse(data, success, fail) {
  if (!data) {
    msg.toastError('访问失败【01】')
    fail && fail(data)
  } else if (data.message) {
    msg.toastError(data.message)
    fail && fail(data)
  } else if (data.success === false) {
    msg.toastError(data.message)
    fail && fail(data)
  } else if (data.success === true) {
    success && success(data.result)
  } else {
    success && success(data)
  }
}

export default {
  get(url, data, params) {
    return new Promise((resolve, reject) => {
      ajax(
        {
          url: url,
          method: 'get',
          params: createData(data),
          success: res => {
            resolve(res)
          },
          fail: null
        },
        res => {
          resolve(res)
        },
        params
      )
    })
  },
  post(url, data, params) {
    return new Promise((resolve, reject) => {
      ajax(
        {
          url: url,
          method: 'post',
          data: createData(data),
          success: res => {
            resolve(res)
          },
          fail: null
        },
        res => {
          resolve(res)
        },
        params
      )
    })
  }
}
