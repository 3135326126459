import Vue from 'vue'
import ElementUI from 'element-ui'
import Print from 'vue-print-nb'

import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/base.less' // global css
import '@/assets/styles/app.less' // global css
import '@/assets/styles/site.less' // global css
import '@/assets/styles/colorui/main.css' // global css

import './permission' // permission control

import lgh from '@/lgh'
import site from './site'

Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
Vue.use(Print) // 注册

Vue.use(lgh)
Vue.use(site)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
