<template>
  <el-row>
    <el-form ref="dataInfo" :model="dataInfo" label-width="120px" class="margin">
      <el-row>
        <el-col class="padding-right-xs">
          <el-row class="twice">
            <el-form-item label="车牌号" prop="carNo">
              <el-input v-model="dataInfo.carNo" autocomplete="off" :disabled="dataInfo.carId > 0"></el-input>
            </el-form-item>
            <el-form-item label="品牌型号" prop="modelName">
              <el-input v-model="dataInfo.modelName" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="车架号" prop="vin">
              <el-input v-model="dataInfo.vin" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="发动机号" prop="engineNo">
              <el-input v-model="dataInfo.engineNo" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="车辆来源" prop="carSourceText">
              <el-input v-model="dataInfo.carSourceText" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="车辆状态" prop="carStatusText">
              <el-input v-model="dataInfo.carStatusText" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="当前里程数" prop="mileage">
              <el-input v-model="dataInfo.mileage" type="number" :disabled="true" style="width:220px;" autocomplete="off">
                <span slot="suffix">公里</span>
              </el-input>
            </el-form-item>
            <el-form-item label="注册日期" prop="registerDate">
              <l-datepicker v-model="dataInfo.registerDate"></l-datepicker>
            </el-form-item>
            <el-form-item label="保险到期(交强险)" prop="insuranceJQXExpireDate">
              <l-datepicker v-model="dataInfo.insuranceJQXExpireDate"></l-datepicker>
            </el-form-item>
            <el-form-item label="保险到期(商业险)" prop="insuranceSYXExpireDate">
              <l-datepicker v-model="dataInfo.insuranceSYXExpireDate"></l-datepicker>
            </el-form-item>
            <el-form-item label="年检到期" prop="yearlyInspectExpireDate">
              <l-datepicker v-model="dataInfo.yearlyInspectExpireDate"></l-datepicker>
            </el-form-item>
            <el-form-item label="保养到期" prop="maintenanceExpireDate">
              <l-datepicker v-model="dataInfo.maintenanceExpireDate"></l-datepicker>
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </el-row>
</template>
<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  mounted() {
    this.dataInfo.carSource = this.dataInfo.carSource.toString()
    this.dataInfo.carStatus = this.dataInfo.carStatus.toString()
  },
  methods: {}
}
</script>
