<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" width="500px">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px" style="padding-right:50px;">
      <el-form-item class="flex-sub" label="输入旧密码" prop="oldPassword">
        <el-input v-model="dataInfo.oldPassword" autocomplete="off" type="password" show-password>
          <i slot="prefix" class="input-icon el-icon-lock"></i>
        </el-input>
      </el-form-item>
      <el-form-item class="flex-sub" label="输入新密码" prop="newPassword">
        <el-input v-model="dataInfo.newPassword" autocomplete="off" type="password" show-password>
          <i slot="prefix" class="input-icon el-icon-lock"></i>
        </el-input>
      </el-form-item>
      <el-form-item class="flex-sub" label="确认新密码" prop="confirmPassword">
        <el-input v-model="dataInfo.confirmPassword" autocomplete="off" type="password" show-password>
          <i slot="prefix" class="input-icon el-icon-lock"></i>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="success" @click="save">修改密码</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  data() {
    return {
      dataInfo: {
        userId: null,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      dataRules: {
        oldPassword: [{ required: true, trigger: 'blur', message: '旧密码不能为空' }],
        newPassword: [{ required: true, trigger: 'blur', message: '新密码不能为空' }],
        confirmPassword: [
          { required: true, trigger: 'blur', message: '新密码不能为空' },
          {
            validator: (rule, value, callback) => {
              this.dataInfo.newPassword !== value ? callback(new Error('确认密码与新密码不一致')) : callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    this.dataInfo.userId = this.userInfo.userId
  },
  methods: {
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.get('api/user/modify/password', this.dataInfo)
          this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
