<template>
  <div class="l-amap-container">
    <div class="l-amap" :id="mapId"></div>
    <!-- <div class="l-amap-ctrl-layer" v-if="mapStyleCtrlVisible">
      <p class="icon icon-layers" @click="mapStyleVisible = !mapStyleVisible"></p>
      <div class="mapstyle" v-if="mapStyleVisible">
        <p v-for="(item, index) in mapStyleList" :key="index" @click="setMapStyle(item.url)"></p>
      </div>
    </div>
    <div class="l-amap-ctrl-layer" v-if="mapStyleCtrlVisible" style="top:50px;">
      <p class="icon el-icon-location" @click="resetCenter"></p>
    </div> -->
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
var map = null
var mapTipMarker
var cursorLine
var textLayer
var markerLayer
var polylineLayer
var polygonLayer = null
var layerCollect = {}
var drawTool = null
export default {
  name: 'LAmap0',
  props: {
    mapStyle: {
      type: String,
      default: 'amap://styles/grey'
    },
    zoom: {
      type: Number,
      default: 11
    },
    center: {
      type: Array,
      default: () => [118.787975, 31.983388]
    },
    texts: {
      type: Array,
      default: null
    },
    markers: {
      type: Array,
      default: null
    },
    polylines: {
      type: Array,
      default: null
    },
    polygons: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      mapId: 'map' + parseInt(Math.random() * 1000),
      mapStyleCtrlVisible: false,
      mapStyleVisible: false,
      mapStyleList: [
        { url: 'amap://styles/normal' },
        { url: 'amap://styles/macaron' },
        { url: 'amap://styles/graffiti' },
        { url: 'amap://styles/whitesmoke' },
        { url: 'amap://styles/dark' },
        { url: 'amap://styles/fresh' },
        { url: 'amap://styles/darkblue' },
        { url: 'amap://styles/blue' },
        { url: 'amap://styles/light' },
        { url: 'amap://styles/grey' }
      ]
      // textLayer: null,
      // markerLayer: null,
      // polylineLayer: null,
      // polygonLayer: null
    }
  },
  created() {
    map = null
    textLayer = null
    markerLayer = null
    polylineLayer = null
    polygonLayer = null
    layerCollect = {}
  },
  mounted() {
    AMapLoader.load({
      key: process.env.VUE_APP_AMAP_KEY, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.ControlBar', 'AMap.ToolBar', 'AMap.MouseTool', 'AMap.PolygonEditor'] // 插件列表
    })
      .then(AMap => {
        this.initMap()
      })
      .catch(e => {
        console.log(e)
      })
  },
  destroyed(a) {
    if (map) {
      map.destroy()
      map = null
    }
  },
  methods: {
    getMap() {
      return map
    },
    defaultMapStyle (url) {
      return 'amap://styles/grey'
      // if (url) {
      //   localStorage.setItem('l_amap_mapstyle', url)
      //   return
      // }
      // var t = localStorage.getItem('l_amap_mapstyle')
      // if (t) {
      //   return t
      // } else {
      //   return 'amap://styles/grey'
      // }
    },
    initMap() {
      map = new AMap.Map(this.mapId, {
        // mapStyle: 'amap://styles/whitesmoke',
        mapStyle: this.mapStyle,
        resizeEnable: true,
        rotateEnable: false,
        pitchEnable: true,
        zoom: this.zoom,
        pitch: 0,
        viewMode: '3D', // 开启3D视图,默认为关闭
        center: this.center
      })
      map.on('complete', () => {
        this.mapStyleCtrlVisible = true
        this.$emit('complete', map)
      })
      // var controlBar = new AMap.ControlBar({
      //   position: {
      //     right: '10px',
      //     top: '10px'
      //   }
      // })
      // controlBar.addTo(map)

      // var toolBar = new AMap.ToolBar({
      //   position: {
      //     right: '40px',
      //     top: '110px'
      //   }
      // })
      // toolBar.addTo(map)
      this.showText(this.texts)
      this.showMarker(this.markers)
      this.showPolyline(this.polylines)
      this.showPolygon(this.polygons)
    },
    setZoomAndCenter(zoom, center, immediately, duration) {
      if (!map) return
      map.setZoomAndCenter(zoom, center, immediately, duration)
    },
    setCenter(center, immediately, duration) {
      if (!map) return
      map.setCenter(center, immediately, duration)
    },
    showText(list) {
      if (!map) return
      if (textLayer == null) {
        textLayer = new AMap.LabelsLayer({ collision: false })
        map.add(textLayer)
      }
      textLayer.remove(textLayer.getAllOverlays())

      if (!list) return
      list.forEach(a => {
        const item = new AMap.LabelMarker(a)
        item.on('click', function(evt) {
          this.$emit('textClick', a.data, item, evt)
        })
        textLayer.add(item)
      })
      this.$emit('rended', map)
    },
    showMarker(list) {
      if (!map) return
      if (markerLayer == null) {
        markerLayer = new AMap.LabelsLayer({ collision: false, zIndex: 200 })
        map.add(markerLayer)
      }
      markerLayer.remove(markerLayer.getAllOverlays())
      if (!list) return
      var mList = []
      list.forEach(a => {
        const item = new AMap.LabelMarker(a)
        a.call && a.call(item)
        item.on('click', evt => {
          this.$emit('markerClick', a.data, item, evt)
        })
        mList.push(item)
      })
      markerLayer.add(mList)
      this.$emit('rended', map)
    },
    showPolyline(list) {
      if (!map) return
      if (polylineLayer == null) {
        polylineLayer = new AMap.OverlayGroup()
        map.add(polylineLayer)
      }
      polylineLayer.clearOverlays()
      if (!list) return
      list.forEach(a => {
        const item = new AMap.Polyline(a)
        if (a.call) a.call(item)
        item.on('click', function(evt) {
          this.$emit('polylineClick', a.data, item, evt)
        })
        polylineLayer.addOverlay(item)
      })
      this.$emit('rended', map)
    },
    showPolygon(list) {
      if (!map) return
      if (polygonLayer == null) {
        polygonLayer = new AMap.OverlayGroup()
        map.add(polygonLayer)
      }
      polygonLayer.clearOverlays()
      if (!list) return
      list.forEach(a => {
        const item = new AMap.Polygon(a)
        if (a.call) a.call(item)
        item.on('click', evt => {
          this.$emit('polygonClick', a.data, item, evt)
        })
        polygonLayer.addOverlay(item)
      })
      this.$emit('rended', map)
    },
    getLayer(name, option, layerType) {
      var _layer = layerCollect[name]

      if (_layer == null) {
        if (layerType === 'LabelsLayer') {
          _layer = new AMap.LabelsLayer(option)
        } else {
          _layer = new AMap.OverlayGroup()
        }
        map.add(_layer)
        layerCollect[name] = _layer
      }
      return _layer
    },
    setLabelsLayer(list, layerOpt = {}) {
      layerOpt = Object.assign(
        {
          option: { collision: false }
        },
        layerOpt
      )
      if (!map || !layerOpt.name) return
      var _layer = this.getLayer(layerOpt.name, layerOpt.option, 'LabelsLayer')
      _layer.remove(_layer.getAllOverlays())

      if (!list) return
      list.forEach(a => {
        const item = new AMap.LabelMarker(a)
        a.call && a.call(item)
        _layer.add(item)
      })
    },
    setOverlayGroup(list, layerOpt = {}) {
      layerOpt = Object.assign(
        {
          option: {}
        },
        layerOpt
      )
      if (!map || !layerOpt.name) return
      var _layer = this.getLayer(layerOpt.name, layerOpt.option)
      _layer.clearOverlays()

      if (!list) return
      list.forEach(a => {
        var type = a.layerType || layerOpt.layerType
        if (!type) return
        const item = new AMap[type](a)
        a.call && a.call(item)
        _layer.addOverlay(item)
      })
    },
    showInfoWindow(option, pos) {
      if (!map) return
      var infoWindow = new AMap.InfoWindow(option)
      infoWindow.open(map, pos)
    },
    setFitView() {
      if (!map) return
      map.setFitView()
    },
    showTipMarker(param) {
      if (!map) return
      var option = Object.assign(
        {
          width: 200,
          height: 200,
          x: 0,
          y: 0,
          labelWidth: 'auto'
        },
        param.option
      )
      if (!mapTipMarker) {
        mapTipMarker = new AMap.Marker({
          // position: [0, 0],
          anchor: 'bottom-center',
          zIndex: 10000,
          offset: [option.x + 35 + option.width / 2, option.y + option.height / 2]
        })
      }
      var _content = ''
      if (param.content instanceof Array) {
        _content = this._createTipMarkerContent(param.content, option)
      } else {
        _content = param.content
      }
      mapTipMarker.setPosition(param.position)
      mapTipMarker.setContent('<div class="amap-info-window" style="width:' + option.width + 'px;height:' + option.height + 'px;">' + _content + '<div class="amap-info-sharp-right"></div>' + '</div>')
      map.add(mapTipMarker)
    },
    hideTipMarker() {
      if (!map) return
      mapTipMarker && map.remove(mapTipMarker)
      mapTipMarker = null
    },
    removeTipMarker() {
      if (!map) return
      mapTipMarker && map.remove(mapTipMarker)
      mapTipMarker = null
    },
    _createTipMarkerContent(list, option) {
      var r = '<div class="lmap-tip-panel">'
      list.forEach(a => {
        r += '<div class="item">'
        r += '<div class="label ' + (a.labelClass || '') + '" style="width:' + option.labelWidth + '">'
        r += a.label
        r += '</div>'
        r += '<div class="text ' + (a.class || '') + '">'
        r += a.text
        r += '</div>'
        r += '</div>'
      })
      r += '</div>'
      return r
    },
    cursorLine(visible, startXY, end, option) {
      if (!map) return
      if (visible === false) {
        cursorLine && map.remove(cursorLine)
        cursorLine = null
        map.off('mapmove', setLinePos)
        map.off('zoomchange', setLinePos)
      } else {
        var start = map.containerToLngLat(startXY)
        if (!cursorLine) {
          cursorLine = new AMap.Polyline({
            strokeColor: 'rgb(154,50,205)',
            strokeOpacity: 0.8,
            strokeWeight: 10,
            showDir: true,
            lineCap: 'round'
          })
        }
        cursorLine.setPath([start, end])
        map.add(cursorLine)
        map.on('mapmove', setLinePos)
        map.on('zoomchange', setLinePos)
      }
      function setLinePos(evt) {
        start = map.containerToLngLat(startXY)
        cursorLine && cursorLine.setPath([start, end])
      }
    },
    setMapStyle(url) {
      if (!map) return
      map.setMapStyle(url)
      this.defaultMapStyle(url)
      this.mapStyleVisible = false
    },
    resetCenter() {
      this.setZoomAndCenter(this.zoom, this.center)
    },
    initDrawTool() {
      drawTool = new AMap.MouseTool(map)
      drawTool.on('draw', function(e) {
        console.log(e)
        console.log(e.obj.getPath())
        setTimeout(() => {
          drawTool.close(true)

          var editor = new AMap.PolygonEditor(map, e.obj)
          editor.open()
        }, 2000)
      })
    },
    drawPolygon() {
      drawTool.polygon({
        fillColor: '#00b0ff',
        strokeColor: '#80d8ff'
        // 同Polygon的Option设置
      })
    }
  },
  watch: {
    texts(e) {
      console.log(e)
      this.showText(e)
    },
    markers(e) {
      this.showMarker(e)
    },
    polylines(e) {
      this.showPolyline(e)
    },
    polygons(e) {
      this.showPolygon(e)
    }
  }
}
</script>
<style lang="less" scoped>
.l-amap-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.l-amap {
  height: 100%;
  width: 100%;
  resize: both;
  cursor: default;
  background-color: rgb(244, 244, 244);
}
.l-amap-ctrl-layer {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 100;
  p.icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #fff center center no-repeat;
    border-radius: 4px;
    user-select: none;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    color: grey;
    font-size: 20px;
  }
  p.icon:hover {
    background-color: rgb(200, 200, 200);
  }
  p.icon-layers {
    background-image: url('../../../image/map/layers.png');
  }
  .mapstyle {
    position: absolute;
    top: 0px;
    right: 40px;
    width: 923px;
    height: 242px;
    background: url('../../../image/map/mapstyle.jpg') center center no-repeat;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    p {
      width: 165px;
      height: 100px;
      // background-color: red;
      border-radius: 5px;
      margin: 11px 9px;
    }
  }
}

.amap-logo {
  opacity: 0;
}
.amap-copyright {
  opacity: 0;
}
</style>
