<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="80%">
    <el-card class="margin-xs">
      <l-description :option="descOption" :data="descData">
        <template slot="assetState">
          <span v-if="dataInfo.assetState == 1">在建</span>
          <span v-else-if="dataInfo.assetState == 2">启用</span>
          <span v-else-if="dataInfo.assetState == 3">封存</span>
          <span v-else-if="dataInfo.assetState == 4">处置</span>
          <span v-else-if="dataInfo.assetState == 5">报废</span>
        </template>
        <template slot="yesOrNo" slot-scope="scope">
          {{ dataInfo[scope.prop] ? '是' : '否' }}
        </template>
        <!--<template slot="temp">
          <span v-if="!dataInfo.temp" class="text-red">未上报</span>
          <span v-if="dataInfo.temp < 37.2">{{ dataInfo.temp }}℃</span>
          <span v-else class="text-red text-bold">{{ dataInfo.temp }}℃</span>
        </template>
        <template slot="isExist">
          <span v-if="dataInfo.isExist == 0">无</span>
          <span v-else-if="dataInfo.isExist == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isDangerArea">
          <span v-if="dataInfo.isDangerArea == 0">无</span>
          <span v-else-if="dataInfo.isDangerArea == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isHS48">
          <span v-if="dataInfo.isHS48 == 0" class="text-red text-bold">无</span>
          <span v-else-if="dataInfo.isHS48 == -1">不需要</span>
          <span v-else-if="dataInfo.isHS48 == 1">有</span>
        </template>
        <template slot="skmColor">
          <div class="flex align-center justify-start">
            <span v-if="dataInfo.skmColor === 'G'" class="bg-green shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.skmColor === 'Y'" class="bg-yellow shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.skmColor === 'R'" class="bg-red shadow" style="padding:10px 30px 10px 30px;"></span>
          </div>
        </template>
        <template slot="xcmColor">
          <div class="flex align-center justify-start">
            <span v-if="dataInfo.xcmColor === 'G'" class="bg-green shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.xcmColor === 'Y'" class="bg-yellow shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="dataInfo.xcmColor === 'R'" class="bg-red shadow" style="padding:10px 30px 10px 30px;"></span>
          </div>
        </template>
        <template slot="img" slot-scope="scope">
          <div class="flex justify-center">
            <el-image style="width:80%;" :src="dataInfo[scope.prop]" :preview-src-list="preList"> </el-image>
          </div>
        </template> -->
      </l-description>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      descOption: {
        size: 'medium ',
        labelStyle: {
          width: '100px'
        }
      },
      descData: null,
      preList: []
    }
  },
  mounted() {
    console.log(this.dataInfo)
    this.descData = [
      { label: '资产编号', text: this.dataInfo.assetCode },
      { label: '资产名称', text: this.dataInfo.assetName },
      { label: '资产分类', text: this.dataInfo.categoryFullName },
      { label: '资产状态', slot: 'assetState' },
      { label: '固定资产', slot: 'yesOrNo', prop: 'isFixed' },
      { label: '国标分类', text: this.dataInfo.nationalCode },
      { label: '管理部门', text: this.dataInfo.departName },
      { label: '管理人', text: this.dataInfo.userName },
      { label: '区域编号', text: this.dataInfo.areaCode },
      { label: '是否盘点', slot: 'yesOrNo', prop: 'isChecked' },
      { label: '定期检查', slot: 'yesOrNo', prop: 'isInspected' },
      { label: '是否可借', slot: 'yesOrNo', prop: 'isBorrowed' },
      { label: '使用部门', text: this.dataInfo.useDepartName },
      { label: '使用人', text: this.dataInfo.useUserName, span: 2 },

      { label: '卡片编号', text: this.dataInfo.cardCode },
      { label: '资产品牌', text: this.dataInfo.brandName },
      { label: '规格型号', text: this.dataInfo.modelName },
      { label: '厂家', text: this.dataInfo.factory },
      { label: '出厂编号', text: this.dataInfo.factoryNum },
      { label: '保修期', text: this.dataInfo.period },
      { label: '精度等级', text: this.dataInfo.accuracyClass },
      { label: '购置日期', text: this.dataInfo.purchaseDate },
      { label: '购置方式', text: this.dataInfo.purchaseTypeName },
      { label: '购置金额', text: this.dataInfo.price },
      { label: '本币原值', text: this.dataInfo.originalValue },
      { label: '计量单位', text: this.dataInfo.unit },
      { label: '使用月限', text: this.dataInfo.monthLimit },
      { label: '检定周期', text: this.dataInfo.testPeriod },
      { label: '检定日期', text: this.dataInfo.testDate },
      { label: '检定单位', text: this.dataInfo.testUnit },
      { label: '检定编号', text: this.dataInfo.certificateNo }
    ]
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
