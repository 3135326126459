<template>
  <el-date-picker
    :type="type"
    v-model="LValue"
    :unlink-panels="true"
    :placeholder="placeholder"
    :format="LFormat.format"
    :value-format="LFormat.valueFormat"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :readonly="readonly"
    :disabled="disabled"
    :size="size"
    @change="onChange"
  >
  </el-date-picker>
</template>

<script>
const defaultPickerFormat = {
  year: { format: 'yyyy年', valueFormat: 'yyyy' },
  month: { format: 'yyyy年MM月', valueFormat: 'yyyy-MM' },
  date: { format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
  dates: { format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
  week: { format: 'yyyy年第WW周', valueFormat: 'yyyy-MM-dd' },
  datetime: {
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss'
  },
  datetimerange: {
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss',
    isRange: true
  },
  daterange: { format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd', isRange: true },
  monthrange: { format: 'yyyy-MM', valueFormat: 'yyyy-MM', isRange: true }
}
export default {
  name: 'LDatepicker',
  props: {
    modelVal: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default: 'date'
    },
    valueFormat: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    startPlaceholder: {
      type: String,
      default: '开始日期'
    },
    endPlaceholder: {
      type: String,
      default: '结束日期'
    },
    default: { type: [String, Array], default: null }
  },
  model: {
    prop: 'modelVal',
    event: 'input'
  },
  data() {
    return {
      LValue: null,
      LFormat: {}
    }
  },
  mounted() {
    if (!this.valueFormat) {
      this.LFormat = defaultPickerFormat[this.type]
    }
    if (this.modelVal) {
      this.LValue = this.modelVal
    }

    if (this.default) {
      this.LValue = this.default
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e)
      if (this.LFormat.isRange) {
        if (e == null) {
          this.$emit('change', null, null)
        } else {
          this.$emit('change', e[0], e[1])
        }
      } else {
        this.$emit('change', e)
      }
    }
  },
  watch: {
    modelVal(value) {
      this.LValue = value
    }
  }
}
</script>
