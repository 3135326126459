<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="90%">
    <l-table :option="tableOption" :data="planList">
      <template slot="itemState" slot-scope="scope">
        <span v-if="scope.row.dealState == 0" class="text-gray">未检测</span>
        <span v-else-if="scope.row.itemState == 1" class="text-green">完好</span>
        <span v-else-if="scope.row.itemState == 2" class="text-red">缺损</span>
      </template>
      <template slot="fee" slot-scope="scope">
        <span v-if="scope.row.dealState == 0" class="text-gray">---</span>
        <span v-else-if="scope.row.itemState == 1" class="text-green">---</span>
        <span v-else-if="scope.row.itemState == 2" class="text-red">{{ scope.row.fee }}</span>
      </template>
    </l-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      planList: null,
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '任务创建时间', prop: 'createTime' },
          { label: '任务开始时间', prop: 'startTime' },
          { label: '任务结束时间', prop: 'endTime' }
        ]
      },
      recordList: null
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
