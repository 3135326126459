<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="full-screen" top="0vh">
    <el-row v-if="dataInfo">
      <div v-if="viewModel == 'iframe'">
        <iframe style="width:100%;border:0;margin:0;padding:0" :style="iframeStyle" :src="viewUrl"></iframe>
      </div>
      <div v-else-if="viewModel == 'img'" :style="iframeStyle" style="display:flex;align-items:center;justify-content:center;background-color:rgba(0,0,0,.05)">
        <img :src="viewUrl" style="max-width:95%;max-height:95%;" />
      </div>
      <div v-else-if="viewModel == 'video'">
        <video style="width:100%;border:0;margin:0;padding:0" :style="iframeStyle" controls :src="viewUrl"></video>
      </div>
      <div v-else-if="viewModel == 'audio'">
        <audio style="width:100%;border:0;margin:0;padding:0" :style="iframeStyle" controls :src="viewUrl"></audio>
      </div>
      <el-card v-else>
        <h2 style="margin:200px;text-align:center;">该文件暂不支持在线预览</h2>
      </el-card>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      viewModel: '',
      viewUrl: '',
      iframeStyle: { height: document.documentElement.clientHeight - 160 + 'px' }
    }
  },
  mounted() {
    if (typeof this.dataInfo === 'string') {
      this.dataInfo = {
        extension: '.jpg',
        downloadUrl: this.dataInfo
      }
    }
    if (this.dataInfo == null) {
      this.viewModel = ''
    } else if (this.dataInfo.extension === '.pdf') {
      this.viewModel = 'iframe'
      this.viewUrl = this.dataInfo.downloadUrl
    } else if (['.png', '.jpg', '.jpeg', '.bmp', '.gif'].indexOf(this.dataInfo.extension) !== -1) {
      this.viewModel = 'img'
      this.viewUrl = this.dataInfo.downloadUrl
    } else if (['.pptx', '.ppt', '.docx', '.doc', '.xls', '.xlsx'].indexOf(this.dataInfo.extension) !== -1) {
      this.viewModel = 'iframe'
      this.viewUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(this.dataInfo.downloadUrl)
    } else if (this.dataInfo.extension === '.mp4') {
      this.viewModel = 'video'
      this.viewUrl = this.dataInfo.downloadUrl
    } else if (this.dataInfo.extension === '.mp3') {
      this.viewModel = 'audio'
      this.viewUrl = this.dataInfo.downloadUrl
    } else {
      this.viewEnable = false
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style scoped>
.el-dialog.fulldialog {
  width: 100%;
}
</style>
