<template>
  <el-row>
    <el-row>
      <el-col class="padding-right-xs" v-if="pageData">
        <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex"> </l-table>
        <l-pager :page="pageData" @change="getPageData"></l-pager>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', prop: 'carNo' },
          { label: 'VIN码', prop: 'vin', overflow: true },
          { label: '型号', prop: 'carModel', overflow: true },
          { label: '出围时间', prop: 'outTime' },
          { label: '入围时间', prop: 'inTime' }
        ]
        // operation: {
        //   width: '160',
        //   buttons: [{ type: 'primary', icon: 'el-icon-place', label: '查看轨迹', click: this.showTrack }]
        // }
      },
      condition: {
        keyword: '',
        carId: this.dataInfo.carId,
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  mounted() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/car/fenceinout/record', ['enterpriseCode', this.condition])
    },
    showTrack(data) {
      this.$emit('action', {
        type: 'GPSTrack',
        start: data.outTime,
        end: data.inTime
      })
    }
  }
}
</script>
