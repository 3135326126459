<template>
  <div class="l-absolute" :class="[anchor, hideType]" :style="[{ 'z-index': zIndex }, divStyle]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LAbsolute',
  props: {
    zIndex: {
      type: Number,
      default: 200
    },
    width: {
      type: (Number, String),
      default: 200
    },
    anchor: {
      type: String,
      default: 'left top'
    },
    hideType: {
      type: String,
      default: ''
    },
    top: {
      type: [Number, String],
      default: null
    },
    left: {
      type: [Number, String],
      default: null
    },
    right: {
      type: [Number, String],
      default: null
    },
    bottom: {
      type: [Number, String],
      default: null
    }
  },
  created() {
    if (this.anchor.indexOf('center') !== -1) {
      this.divStyle['margin-left'] = (0 - this.width) / 2 + 'px'
    }
    if (typeof this.width === 'string') {
      this.divStyle.width = this.width
    } else {
      this.divStyle.width = this.width + 'px'
    }
    if (this.top !== null) {
      this.divStyle.top = this.top + 'px'
    }
    if (this.left !== null) {
      this.divStyle.left = this.left + 'px'
    }
    if (this.right !== null) {
      this.divStyle.right = this.right + 'px'
    }
    if (this.bottom !== null) {
      this.divStyle.bottom = this.bottom + 'px'
    }
  },
  data() {
    return {
      divStyle: {}
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.l-absolute {
  z-index: 200;
  position: absolute;
  transition: all ease-in-out 0.3s;
  /* .el-form {
    .el-form-item {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  } */
}
.l-absolute.center {
  left: 50%;
}
.l-absolute.top {
  top: 10px;
}
.l-absolute.left {
  left: 10px;
}
.l-absolute.right {
  right: 10px;
}
.l-absolute.bottom {
  bottom: 10px;
}
.l-absolute.hide-top {
  top: -200px;
  opacity: 0;
}
.l-absolute.hide-right {
  right: -1200px;
  opacity: 0;
}
.l-absolute.hide-left {
  left: -1200px;
  opacity: 0;
}
</style>
