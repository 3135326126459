<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top10" top="10vh">
    <el-row v-if="dataInfo">
      <el-col class="padding-xs padding-bottom-0">
        <el-card>
          <l-preview :data="dataInfo" :span="12" :columns="dataColumns"> </l-preview>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataColumns: [
        { label: '外勤事由', prop: 'workType' },
        { label: '外勤人', prop: 'userName' },
        { label: '所属部门', prop: 'departName' },
        { label: '登记时间', prop: 'createTime' },
        { label: '计划出发时间', prop: 'planStartTime' },
        { label: '计划返回时间', prop: 'planEndTime' },
        { label: '出发地', prop: 'startPlace' },
        { label: '返回地', prop: 'endPlace' },
        { label: '相关说明', prop: 'desc' }
      ]
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style scoped></style>
