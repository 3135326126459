import ajax from '@/lgh/utils/ajax.js'
import dialog from '@/lgh/utils/dialog.js'
import DetailWork from '@/views/_common/work/detail'
import DetailEVOrder from '@/views/_common/evorder/detail'

import ViewFile from '@/views/common/document/view'

import SelEnterpriseEmployee from '@/views/common/enterprise/selEmployee'
import SelEnterpriseCar from '@/views/common/enterprise/selCar'

import DetailEnterprise from '@/views/common/enterprise/detail'
import DetailEnterpriseEmployee from '@/views/common/enterprise/employeeDetail'
import DetailAsset from '@/views/common/asset/detail'
import DetailAssetBorrow from '@/views/common/asset/borrowDetail'
import ListCar from '@/views/common/car/list'
import DetailCar from '@/views/common/car/detail'
import DetailCarInspect from '@/views/common/car/inspectDetail'
import DetailMyCarBorrow from '@/views/common/myCar/borrowDetail'
import DetailMyCarBorrowPrint from '@/views/common/myCar/borrowDetailPrint'
import DetailAdvicePrint from '@/views/common/advice/print'
import DetailVisitor from '@/views/common/visitor/detail'
import DetailAdvice from '@/views/common/advice/detail'
import DetailProposal from '@/views/common/proposal/detail'
import DetailVirusCollect from '@/views/common/viruscollect/detail'

import DetailDailyReport from '@/views/common/healthy/dailyreport/detail'

import DetailEAsset from '@/views/common/easset/detail'

import DetailSafelyMatter from '@/views/common/safely/matter/detail'
import DetailSafelyRegularProject from '@/views/common/safely/regular/projectDetail'
import DetailSafelyRegularTask from '@/views/common/safely/regular/taskDetail'
import DetailRegularTriggerPlanList from '@/views/common/_regular_trigger/planList'
const site = {
  URL_API: process.env.VUE_APP_API_URL,
  view_file: fileInfo => {
    dialog.openDialog({
      ins: ViewFile,
      title: '文件浏览',
      data: {
        dataInfo: fileInfo
      }
    })
  },
  view_enterprise_detail: enterpriseCode => {
    ajax.get('api/enterprise/info', { enterpriseCode: enterpriseCode }).then(async res => {
      await dialog.openDialog({
        ins: DetailEnterprise,
        title: '企业信息详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_enterpriseEmployee_detail: userId => {
    ajax.get('api/enterprise/employee/info', { userId: userId }).then(async res => {
      await dialog.openDialog({
        ins: DetailEnterpriseEmployee,
        title: '企业员工信息详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  sel_enterpriseEmployee: async (enterpriseCode, extra) => {
    return await dialog.openDialog({
      ins: SelEnterpriseEmployee,
      title: '企业员工选择',
      data: {
        enterpriseCode: enterpriseCode,
        extra: extra
      }
    })
  },
  sel_enterpriseCar: async enterpriseCode => {
    return await dialog.openDialog({
      ins: SelEnterpriseCar,
      title: '企业车辆选择',
      data: {
        enterpriseCode: enterpriseCode
      }
    })
  },
  view_asset_detail: assetId => {
    ajax.get('api/enterprise/asset/detail', { assetId: assetId }).then(async res => {
      await dialog.openDialog({
        ins: DetailAsset,
        title: '资产信息详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_assetborrow_detail: borrowId => {
    ajax.get('api/enterprise/assetborrow/info', { borrowId: borrowId }).then(async res => {
      await dialog.openDialog({
        ins: DetailAssetBorrow,
        title: '借还记录详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  showCarList: option => {
    dialog.openDialog({
      ins: ListCar,
      title: '车辆列表',
      data: {
        dataInfo: option
      }
    })
  },
  view_car_detail: (carId, option) => {
    ajax.get('api/enterprise/ecar/detail', { carId: carId }).then(async res => {
      await dialog.openDialog({
        ins: DetailCar,
        title: '企业车辆信息详细',
        data: {
          dataInfo: res,
          option: option
        }
      })
    })
  },
  view_car_inspectDetail: inspectId => {
    ajax.get('api/enterprise/car/inspect/info', { inspectId: inspectId }).then(async res => {
      await dialog.openDialog({
        ins: DetailCarInspect,
        title: '车辆检查详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_myCarBorrow_detail: borrowId => {
    ajax.get('api/user/mycar/borrow/info', { borrowId: borrowId }).then(async res => {
      await dialog.openDialog({
        ins: DetailMyCarBorrow,
        title: '私车公用记录详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_myCarBorrow_Print: borrowId => {
    ajax.get('api/user/mycar/borrow/info', { borrowId: borrowId }).then(async res => {
      await dialog.openDialog({
        ins: DetailMyCarBorrowPrint,
        title: '私车公用记录打印',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_advice_Print: model => {
    console.log(model)
    dialog.openDialog({
      ins: DetailAdvicePrint,
      title: '建议打印',
      data: {
        dataInfo: model
      }
    })
  },
  view_visitor_detail: visitId => {
    ajax.get('api/enterprise/visit/info', { visitId: visitId }).then(async res => {
      await dialog.openDialog({
        ins: DetailVisitor,
        title: '访客记录详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_advice_detail: adviceId => {
    ajax.get('api/enterprise/advice/info', { adviceId: adviceId }).then(async res => {
      await dialog.openDialog({
        ins: DetailAdvice,
        title: '企业建议详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_proposal_detail: proposalId => {
    ajax.get('api/enterprise/proposal/info', { proposalId: proposalId }).then(async res => {
      await dialog.openDialog({
        ins: DetailProposal,
        title: '企业建议详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_work_detail: workId => {
    ajax.get('api/enterprise/user/work/info', { workId: workId }).then(async res => {
      await dialog.openDialog({
        ins: DetailWork,
        title: '外勤单详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_evorder_detail: orderSeq => {
    ajax
      .get('http://data.saicdh.com/api/evapi/evdata/order/detail', {
        orderSeq: orderSeq
      })
      .then(async res => {
        await dialog.openDialog({
          ins: DetailEVOrder,
          title: '分时订单详细',
          data: {
            dataInfo: res
          }
        })
      })
  },
  view_viruscollect_detail: collectId => {
    ajax
      .get('api/enterprise/viruscollect/info', {
        collectId: collectId
      })
      .then(async res => {
        await dialog.openDialog({
          ins: DetailVirusCollect,
          title: '离宁返宁情况登记信息',
          data: {
            dataInfo: res
          }
        })
      })
  },
  view_virusdailyreport_detail: reportId => {
    ajax
      .get('api/enterprise/virusdailyreport/info', {
        reportId: reportId
      })
      .then(async res => {
        await dialog.openDialog({
          ins: DetailDailyReport,
          title: '防疫日报',
          data: {
            dataInfo: res
          }
        })
      })
  },
  view_easset_detail: assetId => {
    ajax.get('api/enterprise/easset/detail', { assetId: assetId }).then(async res => {
      await dialog.openDialog({
        ins: DetailEAsset,
        title: '资产信息详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_safely_matter_detail: matterId => {
    ajax.get('api/enterprise/safely/matter/info', { matterId: matterId }).then(async res => {
      await dialog.openDialog({
        ins: DetailSafelyMatter,
        title: '隐患问题详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_safely_regular_project_detail: projectId => {
    ajax.get('api/enterprise/safely/regular/project/info', { projectId: projectId }).then(async res => {
      await dialog.openDialog({
        ins: DetailSafelyRegularProject,
        title: '风控项目详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_safely_regular_task_detail: taskId => {
    ajax.get('api/enterprise/safely/regular/task/info', { taskId: taskId }).then(async res => {
      await dialog.openDialog({
        ins: DetailSafelyRegularTask,
        title: '任务详细',
        data: {
          dataInfo: res
        }
      })
    })
  },
  view_regular_trigger_plan: async list => {
    await dialog.openDialog({
      ins: DetailRegularTriggerPlanList,
      title: '定时器计划一览',
      data: {
        planList: list
      }
    })
  }
}
export default site
