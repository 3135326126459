import constant from './_constant'
import api from './_api'
import site from './_site'

export default {
  install(Vue, options) {
    // 全局方法挂载
    Vue.prototype.$constant = constant
    Vue.prototype.$site = site
    Vue.prototype.$api = api
    // Vue.prototype.$heightOffset1 = 225
    // Vue.prototype.$heightOffset2 = 265
  }
}
