<template>
  <el-row>
    <el-col :span="24" class="padding padding-bottom-0">
      <el-steps :active="activeIndex" align-center finish-status="success">
        <el-step title="已预约" :description="evOrder.orderFlow.bookedTime"></el-step>
        <el-step title="已取车" :description="evOrder.orderFlow.rentTime"></el-step>
        <el-step title="已还车" :description="evOrder.orderFlow.returnTime"></el-step>
        <el-step title="已支付" :description="evOrder.orderFlow.payTime"></el-step>
      </el-steps>
    </el-col>

    <el-col :span="8" class="padding-xs">
      <el-card class="margin-bottom-xs">
        <div slot="header" class="clearfix">
          <p>订单信息</p>
        </div>
        <l-preview :data="evOrder.orderInfo" :columns="orderInfoColumns"> </l-preview>
      </el-card>
      <el-card class="">
        <div slot="header" class="clearfix">
          <p>车辆信息</p>
        </div>
        <l-preview :data="evOrder.vehicleInfo" :columns="vehicleInfoColumns"> </l-preview>
      </el-card>
    </el-col>
    <el-col :span="8" class="padding-xs">
      <el-card class=" margin-bottom-xs">
        <div slot="header" class="clearfix">
          <p>会员信息</p>
        </div>
        <l-preview :data="evOrder.membershipInfo" :columns="membershipInfoColumns"> </l-preview>
      </el-card>
      <el-card class="">
        <div slot="header" class="clearfix">
          <p>网点信息</p>
        </div>
        <l-preview :data="evOrder.shopInfo" :columns="shopInfoColumns"> </l-preview>
      </el-card>
    </el-col>
    <el-col :span="8" class="padding-xs">
      <el-card class=" margin-bottom-xs">
        <div slot="header" class="clearfix">
          <p>结算信息</p>
        </div>
        <l-preview :data="evOrder.priceInfo" :columns="priceInfoColumns"> </l-preview>
      </el-card>
      <el-card class="">
        <div slot="header" class="clearfix">
          <p>套餐信息</p>
        </div>
        <l-preview :data="evOrder.orderPackageInfo" :columns="orderPackageInfoColumns"> </l-preview>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activeIndex: 0,
      orderInfoColumns: [
        { label: '订单号', prop: 'orderSeq' },
        { label: '订单状态', prop: 'paymentStatusName' },
        { label: '是否勾选畅行服务费', prop: 'checkInsurance', filter: a => (a === false ? '否' : '是') },
        { label: '计划取车时间', prop: 'planPickupDateTime', filter: a => this.$lgh.limitTo(a, 16) },
        { label: '计划还车时间', prop: 'planReturnDateTime', filter: a => this.$lgh.limitTo(a, 16) },
        { label: '定位方式', prop: 'locationTypeName' },
        { label: '取车soc', prop: 'getSoc' },
        { label: '订单时长', prop: 'costTimeName' },
        { label: '订单里程', prop: 'orderMileage' },
        { label: '订单性质', prop: 'orderPropertyName' },
        { label: '订单类型', prop: 'orderTypeName' },
        { label: '下单来源', prop: 'origin' },
        { label: '取车方式', prop: 'pickupWayName' },
        { label: '还车方式', prop: 'returnWayName' },
        { label: '产品/活动名称', prop: 'activityTypeName' },
        { label: '押金性质', prop: 'preAuthorisedTypeName' }
      ],
      vehicleInfoColumns: [
        { label: '车型名称', prop: 'vehicleModelName' },
        { label: '车牌号', prop: 'vehicleNo' },
        { label: '车辆运营公司', prop: 'operationOrgName' },
        { label: '车辆所属公司', prop: 'orgName' },
        { label: '清洁时间', prop: 'lastCleanTime' }
      ],
      membershipInfoColumns: [
        { label: '会员名', prop: 'name' },
        { label: '手机号', prop: 'mobilePhone' },
        { label: '身份证号', prop: 'idCardNumber' },
        { label: '驾驶号', prop: 'driverCode' },
        { label: '关联企业', prop: 'orgName' },
        { label: '会员卡号', prop: 'cardNo' },
        { label: '渠道来源', prop: 'appPlatName' },
        { label: '备注来源', prop: '' },
        { label: '第几次消费', prop: 'consumeTimes' }
      ],
      shopInfoColumns: [
        { label: '取车网点', prop: 'pickupStoreName' },
        { label: '取车网点编号', prop: 'pickupStoreSeq' },
        { label: '取车网点所属机构', prop: 'pickupStoreOrgName' },
        { label: '还车网点', prop: 'returnStoreName' },
        { label: '还车网点编号', prop: 'returnStoreSeq' },
        { label: '还车网点所属机构', prop: 'returnStoreOrgName' },
        { label: '计划取车网点', prop: 'planPickupStoreName' },
        { label: '计划取车网点编号', prop: 'planPickupStoreSeq' }
      ],
      priceInfoColumns: [
        { label: '原始租金', prop: 'rentAmount' },
        { label: '订单活动金额', prop: 'activityAmount' },
        { label: '还车服务费', prop: 'returnAmount' },
        { label: '畅行服务费', prop: 'insurance' },
        { label: '[减免]', prop: 'exemptionAmount' },
        { label: '优惠券金额', prop: 'couponValue', visible: a => a },
        { label: '优惠券名称', prop: 'couponActivityName', visible: a => a },
        { label: '会员折扣率', prop: 'membershipDiscountAmount' },
        { label: '活动折扣', prop: 'activityDiscountAmount' },
        { label: '购买E币转消费', prop: 'billTimePrecharge', visible: a => a && a !== '0.0' },
        { label: '实付金额', prop: 'realAmount', span: 12 },
        { label: '促销率', prop: 'promotionRate', span: 12 },
        { label: '结算金额', prop: 'finalAmount', span: 12 },
        { label: '应收金额', prop: 'orderAmount', span: 12 }
      ],
      orderPackageInfoColumns: [
        { label: '封顶价', prop: 'dayPrice' },
        { label: '分时单价', prop: 'timeUnitPrice', filter: a => a + '元/分钟' },
        { label: '最小时间单位', prop: 'minimumTimeUnit', filter: a => a + '分钟' },
        { label: '是否有荣时减免', prop: 'timeReduce', filter: a => (a === '0' ? '否' : '未知') }
      ]
    }
  },
  mounted() {
    if (this.evOrder.orderFlow.payTime) {
      this.activeIndex = 4
    } else if (this.evOrder.orderFlow.returnTime) {
      this.activeIndex = 3
    } else if (this.evOrder.orderFlow.rentTime) {
      this.activeIndex = 2
    } else if (this.evOrder.orderFlow.bookedTime) {
      this.activeIndex = 1
    }
  },
  methods: {}
}
</script>
