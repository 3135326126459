<template>
  <el-row v-if="columns">
    <el-col
      v-for="(item, index) in columns"
      :key="index"
      :span="item.span || span"
      v-show="item.visible ? item.visible(data[item.prop]) : true"
      class="flex align-center padding-bottom-xs padding-lr-xs"
    >
      <div class="text-left text-bold padding-right-xs" :style="{ width: width }">
        {{ item.label }}
      </div>
      <div class="flex-sub" :class="(item.align ? 'text-' + item.align : 'text-right') + ' ' + (item.cut ? 'text-cut' : '')">
        <div v-if="item.slot">
          <slot :name="item.slot" :value="data[item.slot]"></slot>
        </div>
        <div v-if="!item.slot" :title="data[item.prop]">
          <p v-if="item.filter">{{ item.filter(data[item.prop], data) }}</p>
          <p v-if="!item.filter">{{ item.prop ? data[item.prop] : '' }}</p>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'LPreview',
  props: {
    span: {
      type: Number,
      default: 24
    },
    data: {
      type: [Object],
      default: null
    },
    width: {
      type: String,
      default: 'auto'
    },
    columns: {
      type: Array,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
  watch: {}
}
</script>
