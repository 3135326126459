<template>
  <div class="map-block">
    <l-amap ref="map" :texts="textList" :markers="markerList" :polylines="polylineList" @mark-click="markClick"></l-amap>
    <div class="slider-block">
      <div class="block">
        <el-slider v-model="sliderValue" :max="max" :format-tooltip="sliderToolTip" @input="sliderInput" @change="sliderChange"> </el-slider>
      </div>
    </div>
  </div>
</template>

<script>
import LAmap from '@/lgh/components/amap/'
import tipMarkImg from '@/assets/image/map/mark2.png'
export default {
  components: { 'l-amap': LAmap },
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      sliderValue: 0,
      max: 0,
      textList: null,
      markerList: null,
      polylineList: null,
      gpsList: null,
      sliderToolTip: e => (this.max === 0 ? e : this.gpsList[e].create_time),
      tipMarker: null
    }
  },
  created() {
    this.getGpsList()
  },
  mounted() {},
  methods: {
    async getGpsList() {
      this.gpsList = await this.$lgh.get(
        'http://data.saicdh.com/api/evapi/common/car/gps',
        {
          carNo: this.evOrder.vehicleInfo.vehicleNo,
          start: this.evOrder.orderFlow.rentTime,
          end: this.evOrder.orderFlow.returnTime
        },
        '正在查询轨迹数据'
      )
      if (!this.gpsList || this.gpsList.length === 0) {
        this.$lgh.toastWarning('此订单无轨迹信息查询')
        return
      }
      this.max = this.gpsList.length
      this.createMapData()
    },
    createMapData() {
      var _start = this.gpsList[0]
      var _end = this.gpsList[this.gpsList.length - 1]
      var line = {
        path: this.gpsList.map(p => [p.lng, p.lat]),
        geodesic: true,
        lineJoin: 'round',
        showDir: true,
        dirColor: 'white',
        strokeColor: '#29b6f6',
        outlineColor: 'white',
        isOutline: true,
        strokeWeight: 6.0,
        zIndex: 100
      }

      var startText = {
        text: '出发',
        anchor: 'center', // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        position: [_start.lng, _start.lat],
        style: {
          'font-size': '20px',
          color: '#fff',
          'background-color': 'blue'
        }
      }

      var endText = {
        text: this.evOrder.orderFlow.returnTime ? '到达' : '当前',
        anchor: 'center', // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        position: [_end.lng, _end.lat],
        style: {
          'font-size': '20px',
          color: '#fff',
          'background-color': 'green'
        }
      }

      var tipMarker = {
        icon: {
          image: tipMarkImg,
          size: [27, 40],
          imageSize: [27, 40]
        },
        position: [_start.lng, _start.lat],
        offset: [-14, -40],
        visible: false,
        call: e => {
          this.tipMarker = e
        }
      }

      this.textList = [startText, endText]
      this.polylineList = [line]
      this.markerList = [tipMarker]

      setTimeout(() => {
        this.$refs.map.setFitView()
      }, 500)
    },
    sliderInput(index) {
      if (this.max === 0) return
      if (this.tipMarker) {
        var item = this.gpsList[index]
        this.tipMarker.setPosition([item.lng, item.lat])
        this.tipMarker.show()
      }
    },
    sliderChange(index) {},
    markClick() {}
  }
}
</script>
<style scoped>
.map-block {
  height: 100%;
  position: relative;
}
.slider-block {
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 40px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.slider-block .block {
  margin: 0px 20px;
}
</style>
